import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, Navigate } from "react-router-dom";
import withRouter from "helpers/withRouters";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { withTranslation } from "react-i18next";
import { reduxForm, Field } from "redux-form";

import * as Validator from "helpers/validators";
import { renderTextField } from "helpers/form";
import { getIsSignedIn } from "store/reducers";
import { signIn } from "store/actions/auth";
import Button from "components/common/button";
import AuthService from "services/auth";
import * as Styled from "../styled";

class SignIn extends React.Component {
  componentDidMount() {
    this.getRedirectResult();
  }

  getRedirectResult = () => {
    const { signIn } = this.props;
    signIn(AuthService.getOAuthResults());
  };

  handleEmailAuth = ({ email, password }) => {
    const { signIn } = this.props;
    signIn(AuthService.signIn(email, password));
  };

  handleGoogleAuth = () => {
    AuthService.googleSignIn();
  };

  handleFacebookAuth = () => {
    AuthService.facebookSignIn();
  };

  render() {
    const { t, handleSubmit, isSignedIn, location } = this.props;
    if (isSignedIn) {
      const referrer = location.state
        ? location.state.type === "orderplan"
          ? "/homeowner/order"
          : location.state.referrer
        : "/homeowner/account";
      return (
        <Navigate
          to={{
            pathname: referrer,
            state: { redirect: "checkout" },
          }}
        />
      );
      //  <Redirect to={{
      //   pathname: referrer,
      //   redirect:'checkout'
      // }} />;
    }

    return (
      <Styled.Container>
        <Styled.Card>
          <CardHeader
            classes={{ root: "card-header" }}
            title={`${t("nameShort")}: ${t("homeowners")}`}
            subheader={t("pleaseSignIn")}
            style={{ padding: "16px 24px" }}
          />
          <CardContent>
            <form onSubmit={handleSubmit(this.handleEmailAuth)}>
              <Grid container spacing={2} justify="space-around">
                <Grid item xs={12}>
                  <Field
                    name="email"
                    label={t("email")}
                    component={renderTextField}
                    validate={Validator.isRequired}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="password"
                    label={t("password")}
                    component={renderTextField}
                    validate={Validator.isRequired}
                    type="password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    sx={{ color: "white" }}
                    fullWidth
                  >
                    {t("signIn")}
                  </Button>
                </Grid>
                <Grid
                    item
                    xs={6}
                    justifyContent="center"
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                        lineHeight: "1.375em",
                      }}
                      variant="caption"
                      align="center"
                  >
                    <Link to="/auth/reset">{t("forgetPasswordMessage")}</Link>

                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                      fontWeight: "500",
                    }}
                    variant="subtitle2"
                    align="center"
                  >
                    {t("useExistingAccount")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleGoogleAuth}
                    color="secondary"
                    fullWidth
                    sx={{ color: "white" }}
                  >
                    Google
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleFacebookAuth}
                    color="secondary"
                    fullWidth
                    sx={{ color: "white" }}
                  >
                    Facebook
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  justifyContent="center"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                      lineHeight: "1.375em",
                    }}
                    variant="caption"
                    align="center"
                  >
                    {t("dontHaveAccount")}{" "}
                    <Link to="/auth/register">{t("createAccount")}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Styled.Card>
      </Styled.Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
});

export default compose(
  withTranslation(),
  reduxForm({
    form: "signin",
  }),
  connect(mapStateToProps, { signIn }),
  withRouter
)(SignIn);
