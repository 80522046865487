import React from "react";
import { connect } from "react-redux";
import { bool, string, shape, number } from "prop-types";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import FormHelperText from "@mui/material/FormHelperText";
import {
  getAgents,
  // getIsListingCoverage,
  getOrderFormInitialValues,
  getListingCoverage,
} from "store/reducers";
import Button from "components/common/button";
import { renderRadioGroup, renderTextField } from "helpers/form";
import * as Validator from "helpers/validators";

import PropertyDetails from "../property-details";
import ContactInfo from "../contact-info";
import PlanAddons from "../plan-addons";
import Plans from "../plans";
import * as Styled from "../styled-components";
import * as StyledOrder from "./styled";
// import Utils from 'helpers/utils';
import AreaManager from "./area-manager";
import PaymentForm from "./payment-form";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import CaptchaPopup from "./captcha";

class OrderForm extends React.Component {
  componentDidMount() {
    let coupon = this.props.getcouponfromURL();
    let options = this.props.getoptionlist();
    options.then((res) => {
      if (Object.keys(res).length > 0) {
        const couponIds = Object.values(res);
        var couponOptions = [];
        couponIds.map((coupon) => {
          var isPresent = couponOptions.some(function (el) {
            return el.label === coupon.label;
          });
          if (!isPresent) {
            couponOptions.push(coupon);
          }
          return couponOptions;
        });
        this.setState({ coupon_list: couponOptions }, () => {
          if (coupon !== null) {
            this.setState({ inputCoupon: coupon }, () => {
              this.searchCoupon(coupon);
              console.log(this.state.inputCoupon, "dealersOverallTotal1");
              this.validate();
            });
            this.props.change("promoCode", coupon);
          }
        });
      }
    });
  }
  state = {
    verified: false,
    agent: null,
    escrows: [],
    selectedoption: "",
    show: false,
    inputCoupon: "",
    size: 0,
    year: 1,
    scfCode: false,
    coupon_list: [],
    openPaymentForm: false,
    openCaptcha: false,
    paid: false,
    showpayment: false,
    buyerCreditvalue: "",
    transactionID: "dummy",
  };

  formRef = React.createRef();

  static propTypes = {
    isListingCoverage: bool.isRequired,
    initialValues: shape({}).isRequired,
    className: string,
    style: shape({}),
    errorMessage: string,
    isLimited: bool,
    limitedAmount: number,
    optionalTotal: number,
  };

  static defaultProps = {
    className: null,
    style: null,
    errorMessage: null,
  };

  /*onRecaptcha = response => {
    if(!this.state.verified)
    {
      this.setState({ verified: Boolean(response) });
    }
    
  };*/

  handlePaymentForm = () => {
    this.setState({ openPaymentForm: false });
  };

  handleOpenCaptchaPopup = () => {
    this.setState({ openCaptcha: true });
  };

  handlesubmitPopup = () => {
    this.setState({ openCaptcha: false });
    this.props.handleSubmit();
  };
  handleCloseCaptchaPopup = () => {
    this.setState({ openCaptcha: false });
  };

  handlePaymentResponse = (transactionId, status) => {
    if (status === true) {
      localStorage.setItem("transaction_id", transactionId);
      this.setState({ openPaymentForm: false });
      this.setState({ transactionID: transactionId }, () => {
        // if (this.form) {
        //  save in local storage
        // console.log("formmmm", this.form.dispatchEvent(new Event("submit")));
        // this.form.dispatchEvent(new Event("submit"));
        // }
        if (this.formRef) {
          try {
            // this.formRef.current.submit();
            const res = this.formRef.current.click();
            console.log(res);
          } catch (error) {
            const res = this.formRef.current.dispatchEvent(new Event("click"));
            console.log(error, "err", res);
          }
        }
      });
    } else {
    }
  };

  setListingCoveragePrepaidAmount = (amount) => {
    this.props.prepaidAmount(amount);
  };

  handleAgentSearch = (prefix) => (agent) => {
    this.props.change(`${prefix}AgentCompany`, agent.company);
    this.props.change(`${prefix}AgentEmail`, agent.email);
    this.props.change(
      `${prefix}AgentPhone`,
      this.formatPhoneNumber(agent.phone)
    );
    this.props.onAgentSelect(prefix, agent, this.props.agents);
  };

  // componentWillReceiveProps({ submitFailed }) {
  //   if (submitFailed) {
  //     Utils.smoothScrollToTop();
  //   }
  // }

  onAgentSelect = (agent) => {
    const { dispatch } = this.props;
    dispatch(change("orders", "areaManager", agent.name));
  };

  onEscrowSearch = (field, query) => {
    this.props
      .onEscrowSearch(field, query)
      .then((val) => {
        const escrows = [];
        for (let key in val) {
          if (val.hasOwnProperty(key)) {
            escrows.push(Object.assign({}, val[key], { key }));
          }
        }
        this.setState({ escrows });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  onEscrowselect = (escrow) => {
    this.props.change("escrowCompany", escrow.titleEscrow);
    this.props.change("escrowOfficer", escrow.titleOfficer);
    this.props.change("escrowEmail", escrow.titleEmail);
    this.props.change("escrowPhone", this.formatPhoneNumber(escrow.titlePhone));
  };
  handleChange = (event) => {
    this.setState({
      selectedoption: event.value,
    });
    this.props.getcouponamount(event.value);
    this.setState({ show: false });
  };
  searchCoupon = (value) => {
    if (!value || value.length < 3) {
      this.setState({ show: false });
      return;
    }
  };
  applyCoupon = (e) => {
    this.setState({
      inputCoupon: e,
    });
    this.searchCoupon(e);
  };
  validate = () => {
    var found = false;
    var selected_coupon = {};
    this.state.coupon_list.map((d) => {
      if (d.label === this.state.inputCoupon) {
        found = true;
        selected_coupon = d;
      }
      return found;
    });
    if (found) {
      this.setState({ show: false });
      if (this.state.inputCoupon === "SBSCF") {
        this.props.change("addons", ["Pre-paid Service Call Fee"]);
      }
    } else {
      this.setState({ show: true });
      selected_coupon.value = 0;
    }
    this.props.getcouponamount(selected_coupon.value);

    return this.state.inputCoupon;
  };
  cancel = () => {
    this.props.getcouponamount(0);
    this.props.change("promoCode", null);
    this.setState({
      inputCoupon: "",
      show: false,
    });
  };
  get_size = (value) => {
    console.log(value);
    this.setState({
      size: value,
    });
    this.props.getHomesize(value);
  };
  get_year = (year) => {
    this.setState({ year });
    this.props.getyear(year);
  };

  getBuyerCredit = (amount) => {
    this.setState({ buyerCreditvalue: amount });
  };
  formatPhoneNumber = (phoneNumber) => {
    let phone = ("" + phoneNumber).replace(/\D/g, "");
    if (phone.length > 3) phone = phone.replace(/.{3}/, "($&)");
    if (phone.length > 9) phone = phone.replace(/.{8}/, "$&-");
    return phone;
  };
  renderField = ({ input, label, meta: { touched, error }, ...rest }) => {
    console.log("input:::::::", input);
    console.log("rest:::::::", rest);
    return (
      <TextField
        fullWidth
        label={label}
        error={error && touched}
        helperText={touched && error}
        {...input}
        {...rest}
      />
    );
  };

  validateForm() {
    let fields =
      this.props.formState.syncErrors &&
      Object.keys(this.props.formState.syncErrors);
    // fields = fields.map((val) => `"${val}"`).join(",");
    // console.log(
    //   fields,
    //   "fields",
    //   Object.keys(this.props.formState.registeredFields)
    // );
    Object?.keys(this.props.formState.registeredFields)?.map((key) => {
      this.props.touch(key);
    });

    const errorEl = document.querySelector(
      fields?.map((fieldName) => `[name="${fieldName}"]`)?.join(",")
    );
    if (errorEl && errorEl.focus) {
      errorEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    return errorEl;
  }

  render() {
    const {
      t,
      className,
      valid,
      style,
      isListingCoverage,
      handleSubmit,
      agents,
      errorMessage,
      isLimited,
      limitedAmount,
      optionTotal,
      planTotal,
      setBuyerCredit,
      buyerCreditvalue,
    } = this.props;
    const { escrows, size, year, scfCode } = this.state;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let location = params.get("plan");
    const placedByOptions = [
      { value: "through escrow", label: "through escrow" },
      { value: "with a credit card", label: "with a credit card" },
    ];
    console.log(this.state.coupon_list, "couponslist+++_");

    // const MakeItem = function(option, i) {
    //   return <MenuItem key={i} value={option}>{option.label}</MenuItem>;
    // };
    return (
      <StyledOrder.Wrapper className={className} style={style}>
        <StyledOrder.Card raised>
          <CardContent>
            <form
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   console.log("submit is called yay909");
              //   handleSubmit();
              // }}
              onSubmit={handleSubmit}
              // ref={(ref) => {
              //   this.form = ref;
              // }}
              // ref={this.formRef}
            >
              <AreaManager
                t={t}
                onAgentSelect={(agent) => this.onAgentSelect(agent)}
              />
              <PropertyDetails
                isListingCoverage={isListingCoverage}
                onhomesizechange={this.get_size}
                optionTotal={
                  isLimited
                    ? (limitedAmount - (planTotal + size * 85) * year).toFixed(
                        2
                      ) * 1
                    : optionTotal
                }
                isLimited={isLimited}
                change={this.props.change}
                // isStreetError={this.props.isStreetError}
              />
              <ContactInfo
                change={this.props.change}
                isListingCoverage={isListingCoverage}
                onBuyerAgentSearch={this.handleAgentSearch("buyer")}
                onSellerAgentSearch={this.handleAgentSearch("seller")}
                agents={agents}
                escrows={escrows}
                onSearch={this.onEscrowSearch}
                onEscrowSelect={this.onEscrowselect}
              />

              {!isListingCoverage && (
                <Plans
                  onChange={(value) => this.props.change("planOption", value)}
                  isLimited={isLimited}
                  limitedAmount={(limitedAmount - optionTotal).toFixed(2) * 1}
                  planTotal={planTotal}
                  size={size}
                  getyearvalue={this.get_year}
                />
              )}
              {!isListingCoverage && (
                <PlanAddons
                  setBuyerCredit={setBuyerCredit}
                  isLimited={isLimited}
                  limitedAmount={limitedAmount}
                  year={year}
                  size={size}
                  checkScf={scfCode}
                  PromoCode={this.state.inputCoupon}
                />
              )}

              <Divider />

              {location !== "listing-coverage" && (
                <Styled.FormGroup>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "30px",
                    }}
                  >
                    <Typography
                      className="label"
                      color="secondary"
                      align="left"
                      variant="caption"
                    >
                      This Home warranty order is being paid...
                    </Typography>
                    <Grid item sm={5}>
                      <Field
                        name="orderedBy"
                        component={renderRadioGroup}
                        validate={Validator.isRequired}
                        className="flex-row"
                        options={placedByOptions}
                      />
                    </Grid>
                  </div>
                </Styled.FormGroup>
              )}

              <Divider />

              {isListingCoverage ? null : (
                <Styled.FormGroup>
                  <Typography
                    className="label"
                    color="primary"
                    align="left"
                    variant="subheading"
                  >
                    {t("common:applyCoupon")}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} align="left">
                      {/* <TextField label="Promo Code" onChange={(event) => this.applyCoupon(event.target.value)} value={this.state.inputCoupon} name="PromoCode"
                      style={{ marginLeft: '5px' }}/> */}
                      <Field
                        name="promoCode"
                        label="Promo Code"
                        component={renderTextField}
                        value={this.state.inputCoupon}
                        onChange={(event) =>
                          this.applyCoupon(event.target.value)
                        }
                      />
                      {this.state.show && (
                        <FormHelperText style={{ marginLeft: "5px" }}>
                          {t("common:invalidCoupon")}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={8} align="left">
                      <Button
                        fullWidth={false}
                        color="primary"
                        onClick={this.validate}
                        style={{ marginRight: "0.8rem", height: "40px" }}
                      >
                        Apply
                      </Button>
                      <Button
                        fullWidth={false}
                        color="primary"
                        onClick={this.cancel}
                        // variant="outlined"
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          borderBottomColor: "rgba(0, 85, 126, 0.5",
                          border: "1px solid rgba(0, 85, 126, 0.5)",
                          color: "#00557E",
                          borderRadius: "10px",
                          height: "40px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Styled.FormGroup>
              )}

              {/* {location === 'listing-coverage' &&
                <Styled.FormGroup>
                  <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '30px' }}>
                    <Typography className="label" color="secondary" align="left" variant="caption">This Home warranty order is being paid...</Typography>
                    <Grid item sm={5}>
                      <Field
                        name="orderedBy"
                        component={renderRadioGroup}
                        validate={Validator.isRequired}
                        className="flex-row"
                        options={placedByOptions}
                      />
                    </Grid>
                  </div>
                </Styled.FormGroup>
              } */}

              <Divider />
              <Styled.FormGroup>
                <Field
                  multiline
                  rows={7}
                  name="comments"
                  label={t("plans:form.comments")}
                  component={renderTextField}
                />
              </Styled.FormGroup>

              {/* <div style={{ marginTop: 20, display: (this.state.verified?'none':'block')}}>
                <ReCAPTCHA
                  sitekey='6Lca3k8UAAAAALTNS6xmspck9Cx1nuu6Az_R-3A4'
                  onChange={this.onRecaptcha}
                />
              </div>  */}
              <Dialog
                className={
                  !isListingCoverage ? "payment_dialog_div_scroll" : ""
                }
                open={this.state.openPaymentForm}
                sx={{ marginTop: "64px" }}
              >
                <PaymentForm
                  closePaymentForm={this.handlePaymentForm}
                  totalAmount={
                    planTotal +
                    optionTotal +
                    (buyerCreditvalue > 0 ? buyerCreditvalue : 0) -
                    Number(this.props.discount)
                  }
                  handlePaymentResponse={this.handlePaymentResponse}
                  isListingCoverage={isListingCoverage}
                  prepaidAmount={this.setListingCoveragePrepaidAmount}
                />
              </Dialog>

              <Button
                fullWidth
                onClick={() => {
                  const err = this.validateForm();
                  if (!err) this.setState({ openPaymentForm: true });
                }}
                // type='submit'
                color="primary"
                disabled={this.props.isSubmitting}
                style={{ marginTop: 20, color: "#fff" }}
              >
                {!isListingCoverage
                  ? t("plans:form.payNow")
                  : t("plans:form.prepay")}
              </Button>

              <div style={{ textAlign: "center", color: "black" }}>
                {" "}
                <p>(or)</p>
              </div>

              <Button
                ref={this.submitButton}
                fullWidth
                // type='submit'
                color="primary"
                disabled={this.props.isSubmitting}
                style={{ marginTop: 20, color: "#fff" }}
                onClick={() => {
                  const err = this.validateForm();
                  if (!err) this.handleOpenCaptchaPopup();
                }}
              >
                {t("plans:form.placeOrder")}
              </Button>

              {!valid && (
                <FormHelperText>{t("plans:form.required")}</FormHelperText>
              )}

              {Boolean(errorMessage) && (
                <StyledOrder.ErrorMessage>
                  {errorMessage}
                </StyledOrder.ErrorMessage>
              )}
              <button ref={this.formRef} hidden type="submit" />
            </form>
          </CardContent>
          <Dialog open={this.state.openCaptcha}>
            <CaptchaPopup
              closeCaptchaPopup={this.handleCloseCaptchaPopup}
              handleSubmit={this.handlesubmitPopup}
            />
          </Dialog>
        </StyledOrder.Card>
      </StyledOrder.Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: getAgents(state),
  // isListingCoverage: getIsListingCoverage(state),
  isListingCoverage: getListingCoverage(),
  initialValues: getOrderFormInitialValues(state),
  change,
  formState: state.form.orders,
});

// const mapDispatchToProps = (dispatch) => {};
export default compose(
  withTranslation(["common", "plans"]),
  connect(mapStateToProps),
  reduxForm({
    form: "orders",
    enableReinitialize: true,
    onSubmitFail: (errors) => {
      console.log(errors, "Errrors+++201");
      const errorEl = document.querySelector(
        Object.keys(errors)
          .map((fieldName) => `[name="${fieldName}"]`)
          .join(",")
      );
      if (errorEl && errorEl.focus) {
        // errorEl.parentElement.focus();
        errorEl.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      // const position =
      //   errorEl.getBoundingClientRect().top +
      //   document.documentElement.scrollTop;
      // const offset = 200;
      // document.querySelector("html").scrollTo({
      //   top: position - offset,
      //   behavior: "smooth",
      // });
    },
  })
)(OrderForm);
