import React, { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import styles from "../maintenance.module.css";
import { useMediaQuery } from "@mui/material";
const YouTubePlaylistPlayer = ({
  videoId,
  scrolled,
  setScrolled,
  containerId,
}) => {
  // const [currentTime, setCurrentTime] = useState(0);
  const below600 = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:992px)");
  const youtubeRef = useRef();
  const playerRef = useRef();
  const handlePlay = (event) => {
    // if (currentTime > 0) {
    // event.target.seekTo(currentTime);
    // setCurrentTime(0);
    // }
  };
  const opts = {
    playerVars: {
      autoplay: 0, // Autoplay the first video
      mute: 1,
      rel: 0,
    },
    height: below600 ? 200 : 415,
    width: "100%",
  };
  const handleMouseEnter = (e) => {
    youtubeRef.current.internalPlayer.playVideo();
  };
  const handleMouseLeave = () => {
    youtubeRef.current.internalPlayer.pauseVideo();
  };
  const handlePause = (event) => {
    // setCurrentTime(event.target.getCurrentTime());
  };
  let player = null;
  const onReady = (event) => {
    player = event.target;
  };
  useEffect(() => {
    if (containerId === scrolled) {
      setTimeout(() => {
        youtubeRef.current.internalPlayer.playVideo();
      }, 1000);
      setScrolled("");
    }
  }, [videoId]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          !entries[0].isIntersecting &&
          youtubeRef.current?.internalPlayer &&
          !isTablet
        ) {
          // player.pauseVideo();
          youtubeRef.current.internalPlayer.pauseVideo();
        }
      },
      { threshold: 0.3 }
    );
    const observer2 = new IntersectionObserver(
      (entries) => {
        if (youtubeRef.current?.internalPlayer && isTablet) {
          // player.pauseVideo();
          if (entries[0].isIntersecting) {
            youtubeRef.current.internalPlayer.playVideo();
          } else {
            youtubeRef.current.internalPlayer.pauseVideo();
          }
        }
      },
      { threshold: 1 }
    );
    if (playerRef.current) {
      observer.observe(playerRef.current);
      observer2.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
        observer2.observe(playerRef.current);
      }
    };
  }, []);

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        // onMouseOver={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        // onBlur={handleMouseLeave}
        // id={videoId}
        ref={playerRef}
      >
        <YouTube
          videoId={videoId}
          opts={opts}
          ref={youtubeRef}
          // onPlay={handlePlay}
          onPause={handlePause}
          onReady={onReady}
        />
      </div>
    </>
  );
};

export default YouTubePlaylistPlayer;
