import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import { compose } from "redux";

import Section from "components/common/section";
import DataService from "services/data";
import {
  submitConsumerOrder,
  submitCharge,
  submitSubscription,
} from "store/actions/data";
import { clearCart } from "store/actions/consumer";
import {
  getConsumerOrderSummary,
  getIsSignedIn,
  getplantype,
  getUserEmail,
  getUserPhone,
  getUserDisplayName,
} from "store/reducers";
import { getFormValues } from "redux-form";
import PaymentMethod from "./payment-method";
import PlanHolder from "./plan-holder";
import ApplyCoupon from "./apply-coupon";
import OrderSummary from "./policy-summary";
import ZcrmService from "services/zohocrm";
// import { clearToken } from "helpers/utils";

const couponOptions = [];
class Checkout extends React.Component {
  state = {
    loading: false,
    showConfirmation: false,
    coupon_value: 0,
    coupon_label: "",
    coupon_list: [],
    contact: [],
  };

  componentDidMount() {
    let access_token = ZcrmService.getToken();
    access_token.then((res) => {
      let search = ZcrmService.searchUser(
        this.props.email,
        res.access_token,
        "Contacts"
      );
      search
        .then((response) => {
          localStorage.setItem(
            "customer_details",
            JSON.stringify(response.data)
          );
          this.setState({ contact: response.data });
        })
        .catch((e) => {
          // if (e.name === "StatusCodeError") clearToken();
        });
    });

    const coupons = DataService.getpromoCodes("homeowner");
    coupons.then((res) => {
      if (Object.keys(res).length > 0) {
        const couponIds = Object.values(res);
        couponIds.map((coupon) => {
          var isPresent = couponOptions.some(function (el) {
            return el.label === coupon.label;
          });
          if (!isPresent) {
            couponOptions.push(coupon);
          }
          return couponOptions;
        });
        this.setState({ coupon_list: couponOptions });
      }
    });
  }

  get_coupon = (coupon) => {
    this.setState({
      coupon_value: coupon.value,
      coupon_label: coupon.label,
    });
  };

  render() {
    const { match, isSignedIn, location, plantype, addons } = this.props;
    const { coupon_list } = this.state;
    return (
      <StyledSection>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} container spacing={2}>
            <Grid item xs={12}>
              <PlanHolder
                navigate={this.props.navigate}
                path={match?.pathname}
                change={this.props.change}
              />
            </Grid>
            {location.claims === undefined && isSignedIn && (
              <Grid item xs={12}>
                <ApplyCoupon
                  path={match?.path}
                  options={coupon_list}
                  getcouponamount={this.get_coupon}
                  plantype={plantype}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <PaymentMethod
                monthlyPrice={location.state.monthlyPrice}
                annualPrice={location.state.annualPrice}
                paymentType={location.state.paymentType}
                policyInfo={location.state.policyInfo}
                addons={addons}
                totalAmount={location.state.totalAmount}
                // disabled={!isSignedIn || loading}
                discount={this.state.coupon_value}
                service_call_fee={
                  location.service_call_fee !== undefined
                    ? location.service_call_fee
                    : undefined
                }
                plantype={plantype}
                navigate={this.props.navigate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            {console.log(location.state, "locationState")}
            <OrderSummary
              totalAmount={location.state.totalAmount}
              discount={this.state.coupon_value}
              address={
                location.claims !== undefined ? location.claims : undefined
              }
              service_call_fee={
                location.service_call_fee !== undefined
                  ? location.service_call_fee
                  : undefined
              }
              addonsPrice={location.state.addonsPrice}
              plantype={plantype}
              policyInfo={location.state.policyInfo}
            />
          </Grid>
        </Grid>
      </StyledSection>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
  order: getConsumerOrderSummary(state),
  plantype: getplantype(state),
  name: getUserDisplayName(state),
  email: getUserEmail(state),
  phone: getUserPhone(state),
  property: getFormValues("homeowner-property-info")(state),
  custom_plan: getFormValues("homeowner-custom-plan")(state),
  addons: getFormValues("homeowner-plan-addons")(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart),
  submitCharge: (promise) => dispatch(submitCharge(promise)),
  submitSubscription: (promise) => dispatch(submitSubscription(promise)),
  submitConsumerOrder: (promise) => dispatch(submitConsumerOrder(promise)),
});

export default compose(
  withTranslation("homeowner"),
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout);

const StyledSection = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.text.primary,
  justifyContent: `flex-start`,
}));
