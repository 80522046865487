import React, { Component } from 'react';

import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Icon from '@mui/icons-material/QuestionAnswer';

class FAQSelect extends Component {
  render() {
    return (
      <List>
        <ListItem>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary="My Systems & Appliances are old.  Are these still covered under my Home Warranty?"
            secondary="Yes! Age of the System or Appliance does not disqualify you from purchasing a Home Warranty."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary="Are there any exclusions or limits to my coverage?"
            secondary={`
              Yes.  We cover items under normal wear and tear.
              For example, a dryer that breaks under normal wear 
              and tear would be covered.  But if a child were to put a toolbox 
              in the dryer and run it and the dryer breaks, this would not be covered.
              Please review what is specifically covered and not covered in the 
              sample Service Contract.
            `}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary="Do I need an inspection report done or show maintenance records before purchasing a Home Warranty?"
            secondary="No.  We do not require an inspection performed or any maintenance records shown before buying a Home Warranty."
          />
        </ListItem>
      </List>
    );
  }
}

export default FAQSelect;
