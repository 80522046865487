import { REPORT_ERROR, DISMISS_ERROR } from "store/actions/app";
import {
  LOAD_AGENT_DATA,
  SUBMIT_ORDER,
  SUBMIT_CLAIM,
  SUBMIT_CHARGE,
  INCREMENT_LOADING,
  DECREMENT_LOADING,
} from "store/actions/data";
import { SEND_CONTACT } from "store/actions/email";
import { SIGN_IN, REGISTER } from "store/actions/auth";
import {
  LOAD_PLAN_OPTIONS,
  LOAD_PLAN_FEATURES,
  LOAD_PLAN_ADDONS,
} from "store/actions/consumer";

import {
  LOAD_WARRANTY_CLAIMS,
  LOAD_WARRANTY_PLANS,
} from "store/actions/profile";

const INITIAL_STATE = {
  loadingCount: 0,
  errors: [],
};

const actionHandlers = new Map([
  [REPORT_ERROR, handleError],
  [DISMISS_ERROR, handleErrorDismissal],
  [LOAD_AGENT_DATA, handleStatusChange],
  [SUBMIT_CHARGE, handleStatusChange],
  [SUBMIT_ORDER, handleStatusChange],
  [SUBMIT_CLAIM, handleStatusChange],
  [SEND_CONTACT, handleStatusChange],
  [SIGN_IN, handleStatusChange],
  [REGISTER, handleStatusChange],
  [LOAD_PLAN_OPTIONS, handleStatusChange],
  [LOAD_PLAN_FEATURES, handleStatusChange],
  [LOAD_PLAN_ADDONS, handleStatusChange],
  [LOAD_WARRANTY_CLAIMS, handleStatusChange],
  [LOAD_WARRANTY_PLANS, handleStatusChange],
  [
    INCREMENT_LOADING,
    (state) => ({ ...state, loadingCount: state.loadingCount + 1 }),
  ],
  [
    DECREMENT_LOADING,
    (state) => ({
      ...state,
      loadingCount: Math.max(state.loadingCount - 1, 0),
    }),
  ],
]);

function handleError(state, action) {
  const { error } = action.payload;
  const { loadingCount } = state;
  const count = loadingCount > 0 ? loadingCount - 1 : 0;

  return { ...state, loadingCount: count, errors: [...state.errors, error] };
}

function handleErrorDismissal(state, action) {
  const { message, index } = action.payload;
  const errors = state.errors.filter((m, i) => m !== message && i !== index);

  return { ...state, errors };
}

function handleStatusChange(state, action) {
  let count = action.payload.loading
    ? state.loadingCount + 1
    : state.loadingCount - 1;

  return { ...state, loadingCount: count || 0 };
}

const AppReducer = (state = INITIAL_STATE, action = { type: null }) =>
  actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;

export default AppReducer;

export const getLoadCount = (state) => state.loadingCount;
export const getErrors = (state) => state.errors;
