import React from 'react';
import { render } from 'react-dom';
import {createRoot} from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import JssProvider from 'react-jss/lib/JssProvider';
// import { create } from 'jss';
// import { createGenerateClassName, jssPreset } from '@mui/material/styles';

import { StyledEngineProvider  } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// import { create } from 'jss';
// import { createGenerateClassName, jssPreset } from '@mui/styles';



import './index.css';
import './i18n';
import 'services/firebase';

import Theme, { ThemeProvider } from 'helpers/theme';
// import registerServiceWorker from 'registerServiceWorker';
import {unregister as unregisterSW} from 'registerServiceWorker';
import store from 'store';
import App from 'components/app';
import ScrollToTop from 'components/common/scroll-to-top';

const container = document.getElementById('root');
const root = createRoot(container); 

// const generateClassName = createGenerateClassName();

// const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
// jss.options.insertionPoint = document.getElementById('jss-insertion-point');
// const generateClassName = createGenerateClassName();
// const jss = create({ plugins: [...jssPreset().plugins] });
// document.addEventListener('DOMContentLoaded', function () {
//   jss.options.insertionPoint = document.getElementById('jss-insertion-point');
// });

const cache = createCache({
  key: 'my-prefix',
});
root.render(
  // <JssProvider jss={jss} generateClassName={generateClassName}>
  <CacheProvider value={cache}>
<StyledEngineProvider injectFirst >
    <ThemeProvider theme={Theme}>
       <Provider store={store}>
         <Router>
           <ScrollToTop>
             <App />
          </ScrollToTop>
        </Router>
      </Provider>
    </ThemeProvider>
    </StyledEngineProvider>
  </CacheProvider>
  // </JssProvider>
 );

unregisterSW();
// registerServiceWorker();
