import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Review from "./Review";
import Button from "components/common/button";
import { decrementLoading } from "store/actions/data";
import { compose } from "redux";
import { connect } from "react-redux";

export class ConfirmOrder extends React.Component {
  componentDidMount() {
    this.props.decrementLoading();
  }
  render() {
    const { t, locationobj } = this.props;
    return (
      <Card
        style={{
          width: "80%",
          margin: "5% auto",
          padding: "3%",
          height: "40%",
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
          style={{ margin: "-4px" }}
        >
          {locationobj?.state.type === "policy" ? (
            <Grid item xs={12}>
              <Typography
                sx={{ textTransform: "unset" }}
                variant="h3"
                color="primary"
              >
                {t("plans:policy.confirmationTitle")}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h3" color="primary">
                {t("plans:order.confirmationTitle")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {locationobj?.state.type === "plans" && (
              <div>
                <Typography variant="subtitle">
                  {t("plans:order.confirmationBody")}
                </Typography>
                <Review
                  t={t}
                  user={locationobj?.state.user}
                  review={locationobj?.state.data}
                  cost={locationobj?.state.cost}
                  optionCost={locationobj?.state.optionTotal}
                  yearPlan={locationobj?.state.year}
                  discount={locationobj?.state.discount}
                ></Review>
              </div>
            )}
            {locationobj?.state.type === "homeowner" && (
              <Typography variant="subtitle">
                Your order has been completed successfully.
              </Typography>
            )}
            {locationobj?.state.type === "policy" && (
              <div>
                <Typography variant="subtitle">
                  {t("plans:policy.confirmationBody")}
                </Typography>
                <Button
                  onClick={() => {
                    this.props.handleNavigation("/");
                  }}
                  fullWidth={false}
                  style={{
                    marginTop: "1rem",
                    height: "40px",
                    lineHeight: "0.5",
                    display: "block",
                  }}
                >
                  Go Home
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  }
}

const mapDispatchToProps = { decrementLoading };

export default compose(
  withTranslation(["common", "plans"]),
  connect(null, mapDispatchToProps)
)(ConfirmOrder);
