import React from "react";
import styled from "@emotion/styled";
import { node, bool, string } from "prop-types";
import { transparentize } from "polished";
import theme from "helpers/theme";

const Main = styled("section")(
  ({ themes, image, imageMask, fullHeight, shadow, style, isContrast }) => {
    const imageUrl = image ? `url("${image}")` : "none";
    const startGradient = transparentize(0.95, theme.palette.primary.main);
    const endGradient = transparentize(0.4, theme.palette.primary.main);
    const backgroundImage = imageMask
      ? `linear-gradient(${startGradient}, ${endGradient}),  ${imageUrl}`
      : imageUrl;
    if (isContrast) {
      style = {
        position: "relative",
        "&::before": {
          content: '""',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          position: "absolute",
          zIndex: 0,
          backgroundColor: "rgba(0,0,0, .3)",
        },
        "&>*": {
          zIndex: 1,
        },
        ...style,
      };
    }

    return {
      label: "common-section",

      alignItems: "center",
      backgroundColor: "initial",
      backgroundImage,
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      boxShadow: shadow ? theme.shadows[4] : "initial",
      color: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: fullHeight ? "100vh" : "1px",
      padding: fullHeight ? 0 : `${theme.spacing(8)} 0`,
      width: "100%",

      ...style,
    };
  }
);

const Article = styled("article")(({ theme }) => ({
  label: "common-section-content",

  width: "100%",
  maxWidth: theme.spacing(180),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

const Wrapper = ({
  children,
  fullHeight,
  image,
  imageMask,
  shadow,
  ...rest
}) => (
  <Main
    image={image}
    imageMask={imageMask}
    fullHeight={fullHeight}
    shadow={shadow}
    {...rest}
  >
    <Article>{children}</Article>
  </Main>
);

Wrapper.propTypes = {
  children: node.isRequired,
  fullHeight: bool,
  imageMask: bool,
  image: string,
  shadow: bool,
};

Wrapper.defaultProps = {
  fullHeight: false,
  image: null,
  imageMask: false,
  shadow: false,
};

export default Wrapper;
