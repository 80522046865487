import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
// import Grid from "@mui/material/Unstable_Grid2";
import Hidden from "@mui/material/Hidden";

import Button from "components/common/button";
import BackgroundImage from "assets/images/family-3.png";
import DesktopLogo from "assets/l-a.png";
import MobileLogo from "assets/logo-full.png";
import styles from "../benefits/benefits.module.css";
import * as Styled from "./styled";
import { Box, Typography } from "@mui/material";

class Banner extends PureComponent {
  state = { show: false };

  componentDidMount() {
    this.setState({ show: true });
  }

  render() {
    const { t, isContrast } = this.props;

    return (
      <Styled.Section
        image={BackgroundImage}
        imageMask
        fullHeight
        isContrast={isContrast}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          style={{ margin: "128px 0 " }}
        >
          <Grid
            style={{ margin: "0 0 20px " }}
            container
            spacing={0}
            item
            sm={6}
            xs={12}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid item xs={8} style={{ margin: "0", padding: "0" }}>
              <Box sx={{ sm: { display: "none" } }} smDown>
                <Styled.Logo src={DesktopLogo} alt="desktop" />
              </Box>

              <div className="hidden600">
                <Styled.Logo src={MobileLogo} alt="mobile" />
              </div>
            </Grid>
            <Grid style={{ padding: "0", margin: "0" }} item md={10} xs={12}>
              <Typography
                style={{ letterSpacing: "-.02em" }}
                color="secondary"
                variant="h1"
                align="center"
                sx={{ sm: { display: "none" } }}
                className="Hidden600"
              >
                {t("home:motto")}
              </Typography>

              <div className="hidden600">
                <Typography color="secondary" variant="h3" align="center">
                  {t("home:motto")}
                </Typography>
              </div>
            </Grid>
            <Grid
              style={{
                width: "80%",
                margin: "-8px",
                padding: "8px",
                maxWidth: "80%",
              }}
              item
              xs={12}
              md={10}
              container
              // spacing={16}
            >
              <Grid
                item
                xs={12}
                sm={4}
                style={{ padding: "8px", lineHeight: "1.4em" }}
              >
                <Button
                  color="primary"
                  // fullWidth
                  onClick={this.props.onNewOrder}
                  className={styles.Button}
                >
                  {t("navigation.orders")}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} style={{ padding: "8px" }}>
                <Button
                  color="primary"
                  // fullWidth
                  // sx={{ minWidth: "112px !important" }}
                  onClick={this.props.onComparePlans}
                  className={styles.Button}
                >
                  {t("home:comparePlans")}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ padding: "8px", minHeight: "40px !important" }}
              >
                <Button
                  color="primary"
                  fullWidth
                  onClick={this.props.onAgentServices}
                  className={styles.Button}
                >
                  {t("navigation.agentServices")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Styled.Section>
    );
  }
}

export default withTranslation(["common", "home", "navigation"])(Banner);
