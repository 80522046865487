import {
  LOAD_WARRANTY_CLAIMS,
  LOAD_WARRANTY_PLANS,
  RESET_STATE,
} from "../actions/profile";

const INITIAL_STATE = {
  plans: {},
  claims: {},
};

const actionHandlers = new Map([
  [LOAD_WARRANTY_CLAIMS, handleWarrantyClaims],
  [LOAD_WARRANTY_PLANS, handleWarrantyPlans],
  [RESET_STATE, () => INITIAL_STATE],
]);

const EmailReducer = (state = INITIAL_STATE, action = { type: null }) =>
  actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;

function handleWarrantyClaims(state, action) {
  const { data } = action.payload;

  return data ? { ...state, claims: data } : state;
}

function handleWarrantyPlans(state, action) {
  const { data } = action.payload;

  return data ? { ...state, plans: data } : state;
}

export default EmailReducer;
