import styled from "@emotion/styled";

import CommonSection from "components/common/section";

export const Section = styled(CommonSection)(({ theme }) => ({
  label: "home-banner",
  marginTop: "5%",
  boxShadow: theme.shadows[4],
  // display: 'flex',
  // flexDirection: 'row',
  // justifyContent: 'flex-end',

  [theme.breakpoints.down("sm")]: {
    background: theme.palette.common.white,
  },
}));

export const Hero = styled("div")(({ theme }) => ({
  label: "banner-hero",

  width: "50%",
  // display: 'flex',
  // flexDirection: 'column',
  alignSelf: "flex-end",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: `${theme.spacing(5)}px 0 0 0`,
  },
}));

export const Logo = styled("img")(({ theme }) => ({
  label: "banner-logo",
  width: "13%",
  marginLeft: "15px",
  // height: '100%',
  position: "absolute",
  left: "0px",
  top: "0px",
  [theme.breakpoints.down("sm")]: {
    top: "-33px",
  },
}));
