import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import { isRequired, isEmail, isValidNumber } from "helpers/validators";
import Button from "components/common/button";
import {renderCheckbox, renderTextField} from "helpers/form";
import * as Styled from "./styled";
import {Grid} from "@mui/material";
import { Trans } from 'react-i18next';
class ContactForm extends Component {
  state = {
    recaptchaPass: false,
    privacyAndTermsAccepted: false,
  };

  onRecaptcha = (response) => {
    this.setState({recaptchaPass: Boolean(response)});
  };

  onAcceptTermAndPrivacy = (response) => {
     this.setState({privacyAndTermsAccepted: Boolean(response.target.checked)});
  };

  formatPhoneNumber = (phoneNumber) => {
    // var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    // var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    // if (match) {
    //   const value = ["(", match[2], ")", match[3], "-", match[4]].join("");
    //   return value;
    // }
    let phone = ("" + phoneNumber).replace(/\D/g, "");
    if (phone.length > 3) phone = phone.replace(/.{3}/, "($&)");
    if (phone.length > 9) phone = phone.replace(/.{8}/, "$&-");
    return phone;
  };

  render() {
    const { handleSubmit, submitting, t } = this.props;

    function getCheckBoxLabelForToc() {
      return (
            <Trans key={1}>
              {t("phoneNumberConsentHelpText")}&nbsp;{t("phoneNumberConsentMsgText")}&nbsp;<a target={'_blank'} href={"/privacy"}>{t("privacyPolicy")}</a> and &nbsp;<a target={'_blank'} href={"/terms"}>{t("termsAndConditions")}</a>.
            </Trans>
      );
    }

    return (
      <Styled.Wrapper>
        <Typography color="secondary" variant="h3" align="center">
          {t("contactMessage")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            className="contact-field"
            label={t("name")}
            component={renderTextField}
            validate={isRequired}
          />
          <Field
            name="from"
            className="contact-field"
            label={t("email")}
            component={renderTextField}
            validate={[isRequired, isEmail]}
            type="email"
          />
          <Field
            name="mobile"
            className="contact-field"
            label={t("phone")}
            component={renderTextField}
            validate={[isValidNumber]}
            onMouseOut={(event) => {
              if (event.target.value && event.target?.value.trim !== "") {
                this.props.change(
                  "mobile",
                  this.formatPhoneNumber(event.target.value)
                );
              }
            }}
            onChange={(e) => {
              if (e.target.value) {
                setTimeout(() => {
                  let number = this.formatPhoneNumber(e.target.value);
                  this.props.change("mobile", this.formatPhoneNumber(number));
                }, 700);
              }
            }}
            onKeyDown={(evt) => {
              const theEvent = evt || window.event;
              const key = theEvent.keyCode || theEvent.which;
              const keyChar = String.fromCharCode(key);
              const regex = /^[0-9.,\b]+$/;
              if (
                !regex.test(keyChar) &&
                (key < 96 || key > 105) // key codes for numeric keypad
              ) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
              }
            }}
          />
          <Field
            name="message"
            className="contact-field"
            multiline
            rows={7}
            label={t("message")}
            component={renderTextField}
            validate={isRequired}
          />
          <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: "8px", color: "rgba(0, 0, 0, 0.87)" }}
          >
            <Field
                name="acceptTermAndPrivacy"
                label={getCheckBoxLabelForToc()}
                component={renderCheckbox}
                onChange={this.onAcceptTermAndPrivacy}
                checked={this.state.privacyAndTermsAccepted}
                sx={{
                  "& .MuiFormControlLabel-label {": {
                    color: "black",
                  },
                  // color: "black",
                }}
                style={{ color: "black" }}
            />
          </Grid>
          <div style={{ marginTop: 20 }}>
            <ReCAPTCHA
              sitekey="6Lca3k8UAAAAALTNS6xmspck9Cx1nuu6Az_R-3A4"
              onChange={this.onRecaptcha}
            />
          </div>

          <Button
            fullWidth
            type="submit"
            sx={{
              "&.Mui-disabled": {
                backgroundColor: "#cccccc",
                border: "1px solid #999999",
                color: '#666666'
              },
            }}
            disabled={submitting || !this.state.recaptchaPass}
            style={{
              marginTop: 20,
              fontWeight: 'bold'
            }}
          >
            {t("send")}
          </Button>
        </form>
      </Styled.Wrapper>
    );
  }
}

export default compose(
  withTranslation("common"),
  reduxForm({ form: "contact" })
)(ContactForm);
