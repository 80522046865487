import LoadingSpinner from "components/common/loading-spinner";
import { Brochure, BrochureMobile } from "constants/plans";
import React, { useEffect } from "react";
import Button from "components/common/button";
import Section from "components/common/section";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMediaQuery } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function BrochurePage() {
  const [loading, setLoading] = React.useState(true);
  const [file, setFile] = React.useState("");
  const isMobile = useMediaQuery("(max-width:772px)");
  const onClick = () => {
    const link = document.createElement("a");
    link.href = Brochure;
    link.setAttribute("download", "digital brochure.pdf");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  async function download() {
    const response = await fetch(isMobile ? BrochureMobile : Brochure);
    const reader = response.body.getReader();

    const chunks = [];
    let done = false;

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      if (readerDone) {
        done = true;
        break;
      }
      chunks.push(value);
    }
    const blob = new Blob(chunks);
    setFile(URL.createObjectURL(blob));
  }
  const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
    BrochureMobile
  )}&embedded=true`;
  useEffect(() => {
    if (!isMobile) download();
  }, []);
  return (
    <>
      {loading ? <LoadingSpinner /> : ""}
      {!isMobile && (
        <Section
          style={{
            backgroundColor: "#E4F3FB",
            marginTop: "-8px",
          }}
          fullHeight
        >
          <div
            style={{
              width: "100%",
              padding: "0 ",
              display: loading ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <Document
              file={file}
              // onLoadSuccess={() => setLoading(false)}
              onLoadError={console.error}
            >
              <div
                style={{
                  position: "fixed",
                  bottom: "78px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "9999",
                  display: !loading ? "block" : "none",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    paddingBottom: "16px",
                    padding: { xs: "8px 16px", md: "16px 32px" },
                    fontSizw: { xs: "14px", md: "inherit" },
                  }}
                  color="secondary"
                  fullWidth
                  onClick={onClick}
                >
                  Download Brochure
                </Button>
              </div>
              {Array.from(new Array(6), (el, index) => (
                <Page
                  renderTextLayer={false}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={4}
                  onRenderSuccess={() => {
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                  }}
                ></Page>
              ))}
            </Document>
          </div>
        </Section>
      )}
      {isMobile && (
        <div
          style={{
            width: "100%",
            padding: "0 ",
            justifyContent: "center",
            minHeight: "100vh",
            margin: "-16px 0",
            backgroundColor: "#E4F3FB !important",
            display: !loading ? "block" : "none",
            color: "#E4F3FB",
            background: "#E4F3FB",
          }}
        >
          <div
            style={{
              position: "fixed",
              bottom: "78px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "9999",
              display: !loading ? "block" : "none",
            }}
          >
            <Button
              sx={{
                width: "100%",
                paddingBottom: "16px",
                padding: { xs: "8px 16px", md: "16px 32px" },
                fontSizw: { xs: "14px", md: "inherit" },
              }}
              color="secondary"
              fullWidth
              onClick={onClick}
            >
              Download Brochure
            </Button>
          </div>
          <iframe
            title="PDF Viewer"
            src={googleDocsUrl}
            width="100%"
            height="100%"
            style={{
              padding: "0",
            }}
            onLoad={() => setLoading(false)}
          />
        </div>
      )}
    </>
  );
}
