import { reportError } from "./app";
import EmailService from "../../services/email";
import { sendOrderAlert, sendClaimAlert } from "../actions/email";

export const LOAD_AGENT_DATA = "data/LOAD_AGENT_DATA";
export const SET_PREFERRED_PLAN = "data/SET_PREFERRED_PLAN";
export const SUBMIT_ORDER = "data/SUBMIT_ORDER";
export const SUBMIT_CLAIM = "data/SUBMIT_CLAIM";
export const SUBMIT_CHARGE = "data/SUBMIT_CHARGE";
export const SUBMIT_SUBSCRIPTION = "data/SUBMIT_SUBSCRIPTION";
export const INCREMENT_LOADING = "INCREMENT_LOADING";
export const DECREMENT_LOADING = "DECREMENT_LOADING";

export const dataLoading = (type) => ({
  type,
  payload: {
    loading: true,
  },
});

export const dataLoaded = (type, data) => ({
  type,
  payload: {
    data,
    loading: false,
  },
});

export const handlePromise = (type, promise, errMessage, dispatch) => {
  dispatch(dataLoading(type));

  return promise
    .then((data) => {
      dispatch(dataLoaded(type, data));

      return data && data.data ? data.data : data;
    })
    .catch((err) => {
      dispatch(reportError(`${errMessage}: ${err.message}`));
    });
};

export const setPreferredPlan = (plan, amount = 0) => ({
  type: SET_PREFERRED_PLAN,
  payload: { data: { plan, amount } },
});

export const loadAgentData = (promise) => (dispatch) => {
  dispatch(dataLoading(LOAD_AGENT_DATA));

  return promise
    .then((data) => dispatch(dataLoaded(LOAD_AGENT_DATA, data)))
    .catch((err) =>
      dispatch(reportError(`Error loading agents: ${err.message}`))
    );
};

export const submitOrder = (promise) => (dispatch) => {
  dispatch(dataLoading(SUBMIT_ORDER));

  return promise
    .then((data) => {
      dispatch(dataLoaded(SUBMIT_ORDER, data));
      dispatch(sendOrderAlert(EmailService.sendOrderAlert(data)));
    })
    .catch((err) =>
      dispatch(reportError(`Error submitting order: ${err.message}`))
    );
};

export const submitClaim = (promise) => (dispatch) => {
  dispatch(dataLoading(SUBMIT_CLAIM));

  return promise
    .then((data) => {
      dispatch(dataLoaded(SUBMIT_CLAIM, data));
      // dispatch(sendClaimAlert(EmailService.sendClaimAlert(data)));
    })
    .catch((err) =>
      dispatch(reportError(`Error submitting claim: ${err.message}`))
    );
};

export const submitCharge = (promise) => (dispatch) =>
  handlePromise(SUBMIT_CHARGE, promise, `Error charging card`, dispatch);

export const submitConsumerOrder = (promise) => (dispatch) =>
  handlePromise(SUBMIT_ORDER, promise, `Error recording order`, dispatch);

export const submitSubscription = (promise) => (dispatch) =>
  handlePromise(
    SUBMIT_SUBSCRIPTION,
    promise,
    `Error creating subscription`,
    dispatch
  );
export const incrementLoading = () => ({
  type: INCREMENT_LOADING,
});

export const decrementLoading = () => ({
  type: DECREMENT_LOADING,
});
