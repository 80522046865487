import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Helmet from "react-helmet";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { change } from "redux-form";

import BackgroundImage from "assets/images/AdobeStockHouse.jpeg";
import * as Styled from "./components/property-popup/styled";

import Section from "components/common/section";
import { submitClaim } from "store/actions/data";
import DataService from "services/data";

import ClaimForm from "./components/claim-form";
import ClaimConfirmation from "./components/claim-confirmation";
import PropertyConfirmation from "./components/property-popup";
import { getIsSignedIn } from "store/reducers";
import { loadWarrantyClaims } from "store/actions/profile";
import ZcrmService from "services/zohocrm";
// import { clearToken } from "helpers/utils";

class Claims extends Component {
  state = {
    submitting: false,
    showConfirmation: false,
    open: false,
    claims: {},
    claims_list: [],
    selectedClaim: {},
    active: false,
  };
  componentDidMount() {
    const { isSignedIn } = this.props;
    if (isSignedIn) {
      this.loadclaims().then((data) => {
        console.log(data, "tokenErr");
        if (data === "tokenErr") this.loadclaims();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSignedIn !== this.props.isSignedIn) {
      if (this.props.isSignedIn) {
        this.loadclaims().then((data) => {
          if (data === "tokenErr") this.loadclaims();
        });
      }
    }
  }

  change_state(val) {
    this.setState({ open: val });
  }

  loadclaims = async () => {
    const {
      auth: { email },
    } = this.props;
    const token = ZcrmService.getToken();
    token
      .then(async (res) => {
        if (res.access_token) {
          let searchPolicies = ZcrmService.getPolicies(res.access_token, email);
          this.props.loadClaims(searchPolicies);
          return searchPolicies;
        }
        //  else {
        //   // async function fetchAllPolicies() {
        //   return new Promise((res) => {
        //     const allPolicies = ZcrmService.getAllPolicies(
        //       res.access_token,
        //       email
        //     );
        //     console.log(allPolicies, "allpoli");
        //     this.props.loadClaims(allPolicies);
        //     allPolicies.then((data) => {
        //       const policies = data.details.output;
        //       const allPolicyArray = "[" + policies + "]";
        //       console.log(allPolicyArray.length, "rr890");
        //       if (allPolicyArray && allPolicyArray.length) {
        //         console.log(JSON.parse(allPolicyArray), "parsed890");
        //         res({ data: JSON.parse(allPolicyArray) });
        //       }
        //     });
        //   });
        // }
        // return await fetchAllPolicies();
        // }
      })
      .then((response) => {
        console.log(response, "res890");
        if (!response) return "tokenErr";
        if (response.data !== null) {
          let activePolicies = response.data?.filter(
            (p) => p.Status === "Active"
          );
          this.getClaimsArray(activePolicies);
          this.setState({ claim: activePolicies });
        }
      })
      .catch((error) => {
        console.log(error, "ooo");
        this.setState({ active: true });
        return error;
      });
  };
  get_property = (value) => {
    if (Object.keys(this.state.claims).length === 0) {
      console.log(value, "value20");
      let addressObj = {
        unit: value.Address_2 ? value.Address_2 : undefined,
        street: value.Address_1 ? value.Address_1 : undefined,
        city: value.City ? value.City : undefined,
        state: value.State ? value.State : undefined,
        postal: value.Zip ? value.Zip : undefined,
      };
      value.address = addressObj;
      value.Phone = this.formatPhoneNumber(value.Phone);
      value.coveragePackage = value.Plan_Name;
      value.Name = value.Customer?.name;
      value.policy = value.Policy_Number;
      console.log(value, "address");
      // this.setState({
      //   claims: {
      //     address: address,
      //   },
      //   open: false,
      // });
      this.setState({
        claims: value,
        open: false,
      });
    }
  };
  inputRef = React.createRef();

  getClaimsArray = (claims_array) => {
    if (claims_array === null) return;
    if (claims_array.length > 0) {
      this.setState({ claims_list: claims_array });
      if (claims_array.length === 1) {
        const value = claims_array[0];
        let addressObj = {
          unit: value.Address_2 ? value.Address_2 : undefined,
          street: value.Address_1 ? value.Address_1 : undefined,
          city: value.City ? value.City : undefined,
          state: value.State ? value.State : undefined,
          postal: value.Zip ? value.Zip : undefined,
        };
        value.address = addressObj;
        value.Phone = this.formatPhoneNumber(value.Phone);
        value.coveragePackage = value.Plan_Name;
        value.Name = value.Customer?.name;
        value.policy = value.Policy_Number;
        this.setState({
          selectedClaim: value,
          open: false,
        });
      } else {
        this.change_state(true);
      }
    } else {
      this.setState({ active: true });
    }
  };
  formatPhoneNumber = (phoneNumber) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const value = ["(", match[2], ")", match[3], "-", match[4]].join("");
      return value;
    }
  };

  handleSubmit = (values) => {
    this.setState({ submitting: true });
    if (Object.keys(this.state.claims).length === 0) {
      let address = {
        unit: values.unit,
        street: values.street,
        city: values.city,
        state: values.state,
        postal: values.postal,
      };
      this.setState({
        claims: {
          address: address,
        },
      });
    }
    console.info("Data service submit claim entry ::::::" + new Date());
    const promise = DataService.submitClaim(values);
    this.props.submitClaim(promise);
    console.info("after data service submit claim entry ::::::" + new Date());
    promise.then(() => {
      const token = ZcrmService.getToken();
      token.then((res) => {
        const policy = ZcrmService.searchPolicy(
          res.access_token,
          values.policy
        );
        policy.then((policyInfo) => {
          console.log("policyInfo", policyInfo);
          const generateClaim = ZcrmService.generateClaim(
            values,
            res.access_token,
            policyInfo
          );
          generateClaim.then((claim) =>
            this.setState({ showConfirmation: true })
          );
          console.info("after claim entry ::::::" + new Date());
        });
      });
    });
  };

  render() {
    const { t, claims } = this.props;

    return (
      <Section image={BackgroundImage} imageMask>
        <PropertyConfirmation
          open={this.state.open}
          claims={claims}
          claims_list={this.state.claims_list}
          onpropertychange={this.get_property}
          onClose={() => this.setState({ open: false })}
          // onClose={() => this.props.history.push('/claims')}
        />
        <Helmet title={t("claims:pageTitle")} />
        <Typography
          color="inherit"
          variant="h1"
          sx={{ letterSpacing: "-.02em" }}
        >
          {t("claims:pageTitle")}
        </Typography>
        <ClaimForm
          onSubmit={this.handleSubmit}
          claims={
            Object.keys(this.state.selectedClaim).length === 0
              ? this.state.claims
              : this.state.selectedClaim
          }
          isSubmitting={this.state.submitting}
          change={this.props.change}
          inputRef={this.inputRef}
        />
        <Styled.Modal
          open={this.state.active}
          onClose={() => this.props.navigate("/")}
          claims={claims}
        >
          <Styled.ModalContent>
            <Typography variant="subtitle1" color="primary">
              You do not have any active policy.
            </Typography>
          </Styled.ModalContent>
        </Styled.Modal>

        <ClaimConfirmation
          open={this.state.showConfirmation}
          onClose={() => this.props.navigate("/")}
          // onClose={() =>
          //   this.props.history.push({
          //     pathname: '/',
          //     stepper:'checkout',
          //     claims:this.state.claims,
          //     service_call_fee:60
          //   })
          // }
        />
      </Section>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  isSignedIn: getIsSignedIn(state),
  claims: state.profile.claims,
  change,
});

const mapDispatchToProps = (dispatch) => ({
  submitClaim: (promise) => dispatch(submitClaim(promise)),
  loadClaims: (promise) => dispatch(loadWarrantyClaims(promise)),
});

export default compose(
  withTranslation(["common", "claims"]),
  connect(mapStateToProps, mapDispatchToProps)
)(Claims);
