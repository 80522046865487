import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Avatar,
} from "@mui/material";
import ZcrmService from "services/zohocrm";

const Profile = ({ email, displayName, photoURL, ...props }) => {
  const [name, setName] = useState("");
  const avatarSource = photoURL
    ? photoURL
    : `https://api.adorable.io/avatars/250/${email}.png`;

  const getUserData = async (email) => {
    const res = await ZcrmService.profileName(email);
    setName(res.details.output);
  };

  useEffect(() => {
    setName(displayName);
    if (displayName !== null) return;
    getUserData(email);
  }, [email]);
  useEffect(() => {
    if (!props.isSignedIn) {
      props.navigate("/auth/signin");
    } else {
      props.loadProfile();
      props.loadData();
      props.loadAllPolicies();
    }
  }, [name, email]);

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader title="Profile" avatar={<Avatar src={avatarSource} />} />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              helperText="Name"
              value={name || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              helperText="Email"
              value={email || ""}
              disabled
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  email: propTypes.string,
  displayName: propTypes.string,
  photoURL: propTypes.string,
};

Profile.defaultProps = {
  email: " ",
  displayName: " ",
  photoURL: " ",
};

export default compose(withTranslation())(Profile);
