export default {
  title: 'What is a Home Warranty?',
  subtitle: `
    Appliances and Mechanical Failures are not typically covered under Home Owner's 
    Insurance Policies, so our Home Warranty fills the gap to provide you full coverage.
    For a low service call fee our specialized contractors will come and fix or replace the covered items. 
    We make home ownership simple, affordable and give you peace of mind.  We've got your back!
  `,

  choosePlan: 'Choose a Plan',
  requestQuote: 'Request a Quote',
  myAccount: 'My Account',

  minimumOptions: '* Must select at least 10 options',

  newWarranty: 'New Home Warranty',
  propertyInfo: 'Property Information',
  bigHouse: 'Sq. Footage Greater than 6,000?',
  squareFootage: 'Square Footage',
  planSelection: 'Plan Selection',
  checkout: 'Checkout',
  goBack: 'Go Back',
  warrantyHolder: 'Warranty Holder',
  applyCoupon:'Promo Code',

  aLaCarte: 'A La Carte Items',
  systemsAndAppliances: 'Systems & Appliances',
  planTotal: 'Plan Total',
  serviceCallFees: 'Service Call Fee',
  serviceCallDescription:'Consumer policies will have a 30 day wait to open service requests',
  //  'A higher service call fee will lower your bill',

  annual: 'Annual',
  monthly: 'Monthly',

  faqTitle: 'FAQs',
  faq: [
    'Regardless of age, make or model of your appliances or systems, your Warranty will cover the repair or replacement of all covered items.',
    'We cover the cost of repairing or replacing the items covered by your Plan.',
    `You don't even need to show maintenance records or inspection reports to purchase a Warranty Plan.`,
    `You get 24/7 Online support for Requesting Service.`,
    `No limit on number of Claims Requests.`,
    `We will save you the hassle of time and effort finding a reputable contractor that you can rely on and trust.`
  ],

  serviceCall: {
    title: 'Set your trade service call fee',
    subtitle: 'With SilverBack Home Warranty, you choose the amount of your service call fee',
    payLess: 'Pay less per service call',
    lowerPayment: 'Lower your monthly payment'
  },

  repairCosts: {
    title: 'Average Repair Costs',
    subtitle: 'Get the protection from a home warranty plan',
    planNow: 'Plan For The Inevitability Of These Systems Breaking By Getting Covered Today',
    benefits: [
      'No home inspection required before purchase.',
      `Full replacement of covered items - if we can't fix it, we'll replace it!`,
      'One call, one low deductible fee'
    ],
    costs: [
      { name: 'Air Conditioning', cost: '$1,998', icon: 'fa-temperature-low' },
      { name: 'Heating Systems', cost: '$1,986', icon: 'fa-fire' },
      { name: 'Electrical Systems', cost: '$1,355', icon: 'fa-plug' },
      { name: 'Water Heater', cost: '$487', icon: 'fa-tint' }
    ]
  },

  whyChoose: {
    title: 'Why Choose Silverback?',
    reasons: [
      'Flexible payment options',
      `If we can't repair it, we'll replace it`,
      'Network of pre-screened, qualified technicians',
      'Request service 24 hours a day, 365 days a year',
      'Experience and stability you can trust'
    ]
  },

  quote: {
    title: 'Get a Free Home Warranty Quote',
    description: `
      Input your information and get a free, personalized home warranty quote.
      You can customize the coverage and pricing to fit your needs. A SilverBack
      Home Warranty helps provide protection for your home and budget!
    `
  }
};
