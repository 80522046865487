import styled from '@emotion/styled'
import MuiCard from '@mui/material/Card';
import MuiContent from '@mui/material/CardContent';

export const Card = styled(MuiCard)({
  '&&': {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const CardContent = styled(MuiContent)({}, ({ theme }) => ({
  '&&': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 30,

    '& .input-amount': {
      marginBottom: 15,
      padding: '0 15px',
      '& input': {
        color: theme.palette.secondary.main,
        paddingBottom: 20,
        textAlign: 'center',
        padding: '5px 10px',
        fontFamily: theme.typography.fontFamily2,
        fontWeight: 600,
        fontSize: 63,
      },
    },
  },
}));
