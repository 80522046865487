import { AUTH_STATE_CHANGE } from '../actions/auth';

const INITIAL_STATE = {
  displayName: null,
  email: null,
  emailVerified: false,
  isAnonymous: false,
  photoURL: null,
  providerData: [],
  signedIn: false,
  uid: null,
  phoneNumber:null
};

const actionHandlers = new Map([[AUTH_STATE_CHANGE, handleAuthChange]]);

function handleAuthChange(state, action) {
  const { user } = action.payload;

  if (user) {
    return {
      ...state,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      photoURL: user.photoURL,
      providerData: user.providerData,
      signedIn: true,
      uid: user.uid,
      phoneNumber:user.phoneNumber
    };
  }

  return INITIAL_STATE;
}

const AuthReducer = (state = INITIAL_STATE, action = { type: null }) =>
  actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;

export default AuthReducer;

export const isSignedIn = state => !state.isAnonymous && state.signedIn;
export const getDisplayName = state => state.displayName;
export const getEmail = state => state.email;
export const getPhoneNumber = state => state.phoneNumber;
