import { get } from "services/firebase";
import { getDatabase, ref, push, set, get as GEt } from "firebase/database";
export default class HomeownerService {
  static getPlanOptions() {
    return get("plans/homeowners/options");
  }

  static getPlanFeatures() {
    return get("plans/homeowners/features");
  }

  static async getPlanAddons() {
    return get("plans/homeowners/addons");
  }
}
