const { useNavigate, useLocation } = require("react-router-dom");

function withRouter(WrappedComponent) {
    return function withRouter(props) {
      const navigate = useNavigate();
      const location = useLocation();
  
      return (
        <WrappedComponent
          {...props}
          navigate={navigate}
          location={location}
        />
      );
    };
  }
  export default withRouter