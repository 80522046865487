import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

export default ({ open, total, onConfirm, onCancel }) => (
  <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="md" open={open}>
    <DialogTitle>Confirm Order</DialogTitle>
    <DialogContent>
      <DialogContentText>
        You are about to renew your policy and your card will be charged $
        {total}.
      </DialogContentText>

      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);
