import React from "react";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Field } from "redux-form";
import Divider from "@mui/material/Divider";
import { withTranslation } from "react-i18next";
import { getPlanTotal, getPlanOptionTotal } from "store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import * as ADDONS from "constants/addons";
import * as Styled from "./styled-components";
import { Search, Info } from "@mui/icons-material";
import e from "cors";
import DetailsModal from "components/common/Modal";
import styles from "../agent.module.css";
const { AddonPriceEnum } = ADDONS;
let OPTIONS = [
  { name: ADDONS.REFIGERATION, price: AddonPriceEnum[ADDONS.REFIGERATION] },
  {
    name: ADDONS.STANDALONE_FREEZER,
    price: AddonPriceEnum[ADDONS.STANDALONE_FREEZER],
  },
  { name: ADDONS.SECOND_KITCKEN, price: AddonPriceEnum[ADDONS.SECOND_KITCKEN] },
  { name: ADDONS.WASHER_DRYER, price: AddonPriceEnum[ADDONS.WASHER_DRYER] },
  { name: ADDONS.WATER_SOFTENER, price: AddonPriceEnum[ADDONS.WATER_SOFTENER] },
  {
    name: ADDONS.ADD_WATER_HEATER,
    price: AddonPriceEnum[ADDONS.ADD_WATER_HEATER],
  },
  { name: ADDONS.CENTRAL_VACUUM, price: AddonPriceEnum[ADDONS.CENTRAL_VACUUM] },
  { name: ADDONS.POOL_HOTTUB, price: AddonPriceEnum[ADDONS.POOL_HOTTUB] },
  { name: ADDONS.SEWAGELINE, price: AddonPriceEnum[ADDONS.SEWAGELINE] },
  // { name: ADDONS.SALT_POOL_HOTTUB, price: AddonPriceEnum[ADDONS.SALT_POOL_HOTTUB] },
  // { name: ADDONS.ICEMAKER, price: AddonPriceEnum[ADDONS.ICEMAKER] },
  { name: ADDONS.SEPTIC_SYSTEM, price: AddonPriceEnum[ADDONS.SEPTIC_SYSTEM] },
  { name: ADDONS.GRINDER_PUMP, price: AddonPriceEnum[ADDONS.GRINDER_PUMP] },
  { name: ADDONS.BOOSTER_PUMP, price: AddonPriceEnum[ADDONS.BOOSTER_PUMP] },
  {
    name: ADDONS.DETACHED_BUILDING,
    price: AddonPriceEnum[ADDONS.DETACHED_BUILDING],
  },
  { name: ADDONS.WELL_PUMP, price: AddonPriceEnum[ADDONS.WELL_PUMP] },
  {
    name: ADDONS.BASEMENT_APARTMENT,
    price: AddonPriceEnum[ADDONS.BASEMENT_APARTMENT],
  },
  { name: ADDONS.ADD_SQ_FT, price: AddonPriceEnum[ADDONS.ADD_SQ_FT] },
  {
    name: ADDONS.PREPAID_SERVICE,
    price: AddonPriceEnum[ADDONS.PREPAID_SERVICE],
  },
  {
    name: ADDONS.NO_FAULT_COVERAGE,
    price: AddonPriceEnum[ADDONS.NO_FAULT_COVERAGE],
  },
  { name: ADDONS.AC_PROTECTION, price: AddonPriceEnum[ADDONS.AC_PROTECTION] },
  // { name: ADDONS.BUYER_CREDIT, price: AddonPriceEnum[ADDONS.BUYER_CREDIT] },
  {
    name: ADDONS.BRAND_FOR_BRAND,
    price: AddonPriceEnum[ADDONS.BRAND_FOR_BRAND],
  },
  {
    name: ADDONS.Sprinkler_Timer_System,
    price: AddonPriceEnum[ADDONS.Sprinkler_Timer_System],
  },
  {
    name: ADDONS.BOILER_SYSTEM,
    price: AddonPriceEnum[ADDONS.BOILER_SYSTEM],
  },
];
class PlanOptions extends React.Component {
  state = {
    newValue: [],
    check_Scf: false,
    buyer_credit_value: 0,
    isOpen: false,
    selectedAddon: "",
  };

  render() {
    const { isLimited, limitedAmount, planTotal, optionTotal, year, t, size } =
      this.props;
    var { newValue } = this.state;
    let buyer_credit_val = -1;
    let options = [].concat(OPTIONS);
    if (isLimited) {
      let subCoupon = this.props.PromoCode === "SBSCF" ? 50 : 0;
      buyer_credit_val =
        (
          limitedAmount -
          (planTotal + size * 85) * year -
          optionTotal +
          subCoupon
        ).toFixed(2) * 1;
      options = options.concat([
        {
          name: ADDONS.BUYER_CREDIT,
          price: buyer_credit_val,
          buyer_credit: true,
        },
      ]);
      this.props.setBuyerCredit(buyer_credit_val);
    }

    const buildOptions = (labels, options, limit, year) => {
      return options.map((option, index) => ({
        ...option,
        label: labels[index],
        disabled: limit >= 0 ? option.price * year > limit : false,
      }));
    };
    const newoptions = [
      // "Water/Gas/Sewage Line Coverage",
      "Boiler System",
      // "Brand For Brand",
      "Sprinkler Timer & System",
    ];
    const renderCheckboxGroup = ({ options, input }) => (
      <Styled.FlexWrap className={styles.addOnList}>
        {options.map((option) => (
          <div style={{ flex: " 1 0 50%", alignItems: "center" }}>
            <FormControlLabel
              key={option.label}
              label={
                <Styled.FlexRow>
                  {option.label !== "Pre-Paid Service Call Fee" &&
                    option.label !== "Buyer Credit" && (
                      <span className="prefix">${option.price * year}</span>
                    )}
                  {option.label === "Pre-Paid Service Call Fee" && (
                    <span className="prefix">${option.price}</span>
                  )}
                  {option.label === "Buyer Credit" && (
                    <span className="prefix">${option.price}</span>
                  )}
                  {option.label}
                  <span
                    style={{
                      fontSize: "14px",
                      marginTop: "1px",
                      marginLeft: "5px",
                      fontStyle: "italic",
                      color: "#00557E",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                    }}
                  >
                    {newoptions.includes(option.label) ? "(New)" : ""}
                  </span>
                </Styled.FlexRow>
              }
              className="flex-wrap-item"
              classes={{
                label:
                  !(input.value.indexOf(option.name) !== -1) && option.disabled
                    ? "disabled-field-label"
                    : "field-label",
              }}
              control={
                <Checkbox
                  color="secondary"
                  checked={
                    input.value.indexOf(option.name) !== -1 ||
                    option.buyer_credit ||
                    (option.label === "Pre-Paid Service Call Fee" &&
                      this.props.PromoCode === "SBSCF")
                  }
                  disabled={
                    (!(input.value.indexOf(option.name) !== -1) &&
                      option.disabled) ||
                    option.buyer_credit ||
                    (option.label === "Pre-Paid Service Call Fee" &&
                      this.props.PromoCode === "SBSCF")
                  }
                  value={option.name}
                  onChange={(event) => {
                    newValue = [...input.value];
                    if (event.target.checked) {
                      newValue.push(option.name);
                    } else {
                      newValue.splice(newValue.indexOf(option.name), 1);
                    }
                    this.props.setBuyerCredit(buyer_credit_val);
                    return input.onChange(newValue);
                  }}
                />
              }
            />
            {option.label !== "Buyer Credit" &&
              option.label !== "Detached Building Coverage" && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isOpen: true });
                    this.setState({ selectedAddon: option.name });
                  }}
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                >
                  <Info
                    sx={{
                      width: "20px",
                      // color: "rgba(0, 0, 0, 0.6)",
                      color: "rgb(51, 119, 151)",
                      textAlign: "center",
                      marginLeft: "4px",
                      marginTop: "8px",
                    }}
                  />
                </span>
              )}
          </div>
        ))}
      </Styled.FlexWrap>
    );

    return (
      <Styled.FormGroup>
        <Typography
          className="label"
          color="primary"
          align="left"
          variant="subheading"
        >
          {t("plans:addonTitle")}
        </Typography>
        <Divider />
        <Field
          name="addons"
          options={buildOptions(
            t("plans:addonLabels"),
            options,
            buyer_credit_val,
            year
          )}
          component={renderCheckboxGroup}
        />
        <DetailsModal
          open={this.state.isOpen && this.state.selectedAddon}
          onClose={() => this.setState({ isOpen: false })}
          selectedAddon={this.state.selectedAddon}
        />
      </Styled.FormGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  planTotal: getPlanTotal(state),
  optionTotal: getPlanOptionTotal(state),
});

export default compose(
  withTranslation(["common", "plans"]),
  connect(mapStateToProps)
)(PlanOptions);
