import React from "react";
import { withTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { compose } from "redux";
// import { withStyles } from '@mui/material/styles';
import { transparentize } from "polished";
import styles from "./faq.module.css";
import Logo from "assets/logo.png";

const FAQ = ({ t, classes, style }) => (
  <React.Fragment>
    <Grid
      container
      spacing={1}
      sx={{ padding: "8px !important", margin: "16px -8px -8px" }}
    >
      {t("faq").map((faq, index) => (
        <Grid item sm={4} xs={12} key={`faq-${index}`} sx={{ padding: "8px" }}>
          <Card className={styles?.card} style={{ height: "100%" }}>
            <CardHeader
              avatar={
                <img className={styles?.icon} src={Logo} alt="Silverback" />
              }
              title={faq}
              titleTypographyProps={{ color: "inherit", variant: "body1" }}
              style={{ height: "100%", padding: "16px 24px" }}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  </React.Fragment>
);

// const styles = theme => ({
//   card: {
//     backgroundColor: transparentize(0.05, theme.palette.primary.light),
//     color: theme.palette.common.white
//   },
//   icon: {
//     width: 50
//   }
// });

export default compose(
  withTranslation("homeowner")
  // withStyles(styles)
)(FAQ);
