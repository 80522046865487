import styles from "../maintenance.module.css";
import { Typography } from "@mui/material";
import YouTubePlaylistPlayer from "./Player";
import { useEffect, useRef, useState } from "react";

export const Lists = ({ videos, selectVideo, selected }) => {
  return (
    <div className={styles.videoGrid}>
      {videos.map((video, index) => (
        <div
          key={video.id}
          className={`${styles.videoDesc} ${
            selected === index ? styles.playing : ""
          }`}
        >
          <img
            src={video.snippet.thumbnails?.medium?.url}
            alt={video.snippet.title}
            onClick={() => selectVideo(index)}
          />
          <div className={styles.videotitle}>
            <h3 onClick={() => selectVideo(index)}>{video.snippet.title}</h3>
            <p>{video.snippet.description}</p>
            {video.snippet?.description?.length > 100 && (
              <a onClick={() => selectVideo(index)}>See more</a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

function Playlist({
  title,
  desc,
  videos,
  selectVideo,
  selected,
  isless992px,
  id,
  scrolled,
  setScrolled,
}) {
  return (
    <div className={styles.Playlist}>
      {title && (
        <>
          <Typography sx={{ fontSize: "50px" }} variant="h3">
            {title}
          </Typography>
          <Typography
            sx={{ paddingTop: "16px", color: "rgba(0, 0, 0, 0.54)" }}
            variant="body1"
          >
            {desc}
          </Typography>
        </>
      )}
      <div
        className={styles.PlayerGrid}
        id={videos[selected]?.snippet.resourceId.videoId + id}
      >
        <YouTubePlaylistPlayer
          videoId={videos[selected]?.snippet.resourceId.videoId}
          title={videos[selected]?.snippet.title}
          desc={videos[selected]?.snippet.description}
          containerId={videos[selected]?.snippet.resourceId.videoId + id}
          scrolled={scrolled}
          setScrolled={setScrolled}
        />
        {!isless992px && videos.length > 1 && (
          <Lists
            videos={videos}
            selected={selected}
            selectVideo={selectVideo}
          />
        )}
      </div>
    </div>
  );
}

export default Playlist;
