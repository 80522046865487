import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import styled from "@emotion/styled";

const renderListItems = (features) =>
  features.map((o, index) => (
    <ListItem key={`item-${index}`} dense divider>
      <ListItemText
        primaryTypographyProps={{ align: "center" }}
        style={{ height: 18 }}
      >
        <CheckIcon style={{ fontSize: "1rem", color: "green" }} />
      </ListItemText>
    </ListItem>
  ));

const SimplePlan = ({ plan, features, selected, onSelect }) => (
  <StyledCard>
    <CardHeader
      title={plan.label}
      titleTypographyProps={{ color: "secondary" }}
      action={
        <Checkbox
          checked={selected}
          style={{ paddingTop: 8 }}
          onChange={onSelect}
        />
      }
      style={{ maxHeight: 68 }}
    />
    <CardContent>
      <List>{renderListItems(features)}</List>
    </CardContent>
  </StyledCard>
);

SimplePlan.defaultProps = {
  plan: {},
};

const StyledCard = styled(Card)(() => ({
  height: "100%",
}));

export default SimplePlan;
