import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import styled from "@emotion/styled";

import { getConsumerOrderSummary } from "store/reducers";

class OrderSummary extends React.Component {
  static defaultProps = {
    order: {},
  };

  renderPropertyInformation = () => {
    const {
      order: { property },
      address,
    } = this.props;
    var addressLine1, addressLine2;
    if (address === undefined) {
      addressLine1 = property.unit
        ? `${property.street} Unit ${property.unit}`
        : property.street;
      addressLine2 = `${property.city ?? ""}  ${property.state ?? ""} ${
        property.postal ?? ""
      }`;
    } else {
      addressLine1 = address.address.unit
        ? `${address.address.street} Unit ${address.address.unit}`
        : address.address.street;
      addressLine2 = `${address.address.city ?? `${address.address.city}`} ${
        address.address.state ?? ` ${address.address.state}`
      } ${address.address.postal ?? `${address.address.postal}`}`;
    }
    return (
      <React.Fragment>
        <ListSubheader disableGutters>
          <ListItemText
            primary="Covered Property"
            primaryTypographyProps={{
              variant: "subtitle1",
              color: "secondary",
            }}
          />
        </ListSubheader>
        <ListItem disableGutters>
          <ListItemText
            sx={{ color: "rgba(0, 0, 0, 0.87)" }}
            primary={addressLine1}
            secondary={addressLine2}
          />
        </ListItem>
      </React.Fragment>
    );
  };

  renderAddons = () => {
    const {
      order: { addons },
    } = this.props;

    if (!addons || !addons.length) return null;

    const text = addons.join(", ");

    return (
      <React.Fragment>
        <ListSubheader disableGutters>
          <ListItemText
            primary="A La Carte Options"
            primaryTypographyProps={{
              variant: "subtitle1",
              color: "secondary",
            }}
          />
        </ListSubheader>

        <ListItem disableGutters>
          <ListItemText
            primary={text}
            primaryTypographyProps={{ variant: "caption" }}
            sx={{ color: "rgba(0, 0, 0, 0.87)" }}
          />
        </ListItem>
      </React.Fragment>
    );
  };

  renderFeatures = () => {
    const {
      order: { features },
    } = this.props;

    if (!features) return null;

    const text = features.join(", ");

    return (
      <React.Fragment>
        <ListSubheader disableGutters>
          <ListItemText
            primary="Plan Features"
            primaryTypographyProps={{
              variant: "subtitle1",
              color: "secondary",
            }}
          />
        </ListSubheader>

        <ListItem disableGutters>
          <ListItemText
            primary={text}
            primaryTypographyProps={{ variant: "caption" }}
            sx={{ color: "rgba(0, 0, 0, 0.87)" }}
          />
        </ListItem>
      </React.Fragment>
    );
  };

  renderOrderSummary = () => {
    const { order, service_call_fee, discount } = this.props;
    return (
      <React.Fragment>
        {this.props.address === undefined && (
          <ListItem disableGutters>
            <ListItemText
              primary="Home Warranty"
              primaryTypographyProps={{
                variant: "subtitle1",
                color: "secondary",
              }}
            />
            <ListItemSecondaryAction>
              <ListItemText
                primary={order.plan}
                sx={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: "700" }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        <ListItem disableGutters>
          <ListItemText
            primary="Service Call Fee"
            primaryTypographyProps={{
              variant: "subtitle1",
              color: "secondary",
            }}
          />
          <ListItemSecondaryAction>
            <ListItemText
              primary={
                service_call_fee !== undefined
                  ? `$${service_call_fee}`
                  : `$${order.serviceCallFee}`
              }
              sx={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: "700" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        {discount > 0 && (
          <ListItem disableGutters>
            <ListItemText
              primary="Promo Code"
              primaryTypographyProps={{
                variant: "subtitle1",
                color: "secondary",
              }}
            />
            <ListItemSecondaryAction>
              <ListItemText
                primary={`- $${discount}`}
                sx={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: "700" }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {this.renderFeatures()}

        {this.renderAddons()}
      </React.Fragment>
    );
  };

  render() {
    const {
      order: { total },
      discount,
      service_call_fee,
      plantype,
    } = this.props;

    return (
      <StyledCard>
        <CardHeader
          title="Order Summary"
          titleTypographyProps={{ color: "primary" }}
          sx={{ padding: "16px 24px" }}
        />
        <CardContent sx={{ padding: "16px 24px" }}>
          <List>
            {this.renderPropertyInformation()}
            {this.renderOrderSummary()}

            <ListItem disableGutters>
              <ListItemText
                primary="Total"
                primaryTypographyProps={{ variant: "subtitle1" }}
                sx={{ color: " rgba(0, 0, 0, 0.54)", fontSize: "2.125rem" }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    service_call_fee !== undefined
                      ? `$${service_call_fee}`
                      : plantype === "annual"
                      ? total.annual - discount > 0
                        ? `$${total.annual - discount}`
                        : `$${total.annual}`
                      : total.monthly - discount > 0
                      ? `$${total.monthly - discount}`
                      : `$${total.monthly}`
                  }
                  sx={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: "700" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </StyledCard>
    );
  }
}

const mapStateToProps = (state) => ({
  order: getConsumerOrderSummary(state),
});

export default compose(
  withTranslation("homeowner"),
  connect(mapStateToProps)
)(OrderSummary);

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  color: theme.palette.common.white,
}));
