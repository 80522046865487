import browserDetect from "browser-detect";

const browser = browserDetect();
const showValidationResult = (value, errMsg) => (value ? undefined : errMsg);
const getTranslatedError = (form, err) => form.i18n.t(err);

export const isDefined = (value) =>
  typeof value !== "undefined" && value !== null;

export const isRequired = (value) => {
  return !isDefined(value) ? "Required" : undefined;
};
export const lengthOne = (value) => {
  return value && value.length > 0 ? undefined : "Required";
};

export const isValidDate = (value) => {
  if (value !== undefined) {
    var date = value.split("-");
    if (browser.name !== "safari") {
      return date[0] >= 1900 && date[0] <= 9999 ? undefined : "Invalid Date";
    } else {
      var newDate = date[2] + "-" + date[0] + "-" + date[1];

      return date[2] >= 1900 &&
        date[2] <= 9999 &&
        /^(?:\d{4}-(?:(?:(?:(?:0[13578]|1[02])-(?:0[1-9]|[1-2][0-9]|3[01]))|(?:(?:0[469]|11)-(?:0[1-9]|[1-2][0-9]|30))|(?:02-(?:0[1-9]|1[0-9]|2[0-8]))))|(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00)-02-29)$/.test(
          newDate
        )
        ? undefined
        : "please enter the date in MM/DD/YY Format";
    }
  } else {
    return "Invalid Date";
  }
};

export const isValidNumber = (value) => {
  if (value === null) return;
  if (value === undefined) return;
  if (
    value.length <= 13 &&
    (/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(value) || /^[0-9]{10}$/.test(value))
  ) {
    return undefined;
  } else {
    return "Please enter a valid phone number";
  }
};

export const numberLengthCheck = (value) => {
  if (value === null) return;
  if (value === undefined) return;
  if (
    value.length <= 13 &&
    (/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(value) || /^[0-9]{10}$/.test(value))
  ) {
    return undefined;
  } else {
    return "Please enter a valid phone number";
  }
};

export const isValidExpiryDate = (value) => {
  if (/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(value)) {
    return undefined;
  } else {
    return "Please enter a valid expiry date";
  }
};

export const isValidReqNumber = (value) => {
  if (value === undefined) return "Please enter a valid phone number";
  if (
    value.length <= 13 &&
    (/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(value) || /^[0-9]{10}$/.test(value))
  )
    return undefined;
  else {
    return "Please enter a valid phone number";
  }
};

export const isNumber = (value, values, form) => {
  return showValidationResult(
    !isNaN(Number(value)),
    getTranslatedError(form, "validationErrors.NaN")
  );
};

export const isInteger = (value, values, form) =>
  showValidationResult(
    Number.isInteger(Number(value)),
    getTranslatedError(form, "validationErrors.notInteger")
  );

export const isNumberInRange = ({ from, to }, ...rest) => {
  const [value, , form] = rest;
  return showValidationResult(
    value >= from && value <= to,
    getTranslatedError(form, "validationErrors.notInRange")
  );
};

export const isEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? undefined
    : "Please provide a valid email";

export const isValidEmail = (value) => {
  if (
    value === undefined ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return undefined;
  } else {
    return "Please provide a valid email";
  }
};

export const isValidAdditionalEmail = (value) => {
  if (
    value === undefined ||
    //eslint-disable-next-line
    /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(,\s|\s*,|\s*$))*$/.test(
      value
    )
  ) {
    // eslint-disable-line
    return undefined;
  } else {
    return "Please provide a valid email";
  }
};
export const matchPassword = (value, values) => {
  return value === values.password ? undefined : `Passwords don't match`;
};

export const isValidName = (value) => {
  if (
    value === undefined ||
    /^(?=.{4,})[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(value.trim())
  ) {
    return undefined;
  } else {
    return "Please provide a valid name";
  }
};

export const isZipCode = (value) => {
  if (value === undefined || /^\d{5}(-\d{4})?$/.test(value)) {
    return undefined;
  } else {
    return "Please provide a valid zipCode";
  }
};

export function luhnCheck(cardNumber) {
  let sum = 0;
  let shouldDouble = false;
  // Loop through the card number from right to left
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);
    if (shouldDouble) {
      // Double every second digit and subtract 9 if the result is greater than 9
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  // The card number is valid if the sum is divisible by 10
  return sum % 10 === 0 ? undefined : "Please Enter a Valid Credit Card Number";
}

export const validCvv = (cvv) => {
  if (cvv.length !== 3 && cvv.length !== 4) {
    return "invalid cvv";
  }
};

export const expiryMonth = (month) => {
  month = Number(month);
  if (month < 1 || month > 12) {
    return "Invalid expiration date";
  }
};
export const expiryYear = (year) => {
  const currentYear = new Date().getFullYear();
  if (year < currentYear) {
    return "Invalid expiration year";
  }
};
