import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";
// import { withStyles } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { transparentize } from "polished";

import BackgroundImage from "assets/images/AdobeStockHouse.jpeg";
import Section from "components/common/section";
import Button from "components/common/button";
import StarRatings from "react-star-ratings";
import Slider from "react-slick";
import styles from "./repair.module.css";
export class RepairCosts extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      places: [],
    };
  }
  componentDidMount() {
    new window.google.maps.places.PlacesService(this.myRef.current).getDetails(
      {
        placeId: "ChIJZ52ec4yBTYcRK4AOrYyn1mk",
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          place.reviews !== undefined
            ? this.setState({ places: place.reviews })
            : this.setState({ places: [] });
        }
      }
    );
  }
  renderCosts = (costs) => {
    const { classes } = this.props;

    return costs.map((cost, index) => (
      <Grid key={`cost-${index}`} item xs={12} sm={6} sx={{ padding: "8px" }}>
        <Card className={styles?.card}>
          <CardMedia className={styles?.cardMedia}>
            <span className={`fa ${cost.icon} ${styles?.cardIcon}`} />
          </CardMedia>
          <CardContent
            sx={{ padding: "16px 24px 24px" }}
            className={styles?.cardContent}
          >
            <Typography color="primary" variant="subtitle1">
              {cost.name}
            </Typography>
            <Typography color="secondary" variant="h3">
              {cost.cost}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  renderServiceCard = (review) => {
    return (
      <Card style={{ width: "98%" }}>
        <CardHeader
          avatar={
            <CardMedia
              style={{ width: "50px", height: "50px" }}
              image={review.profile_photo_url}
            />
          }
          title={review.author_name}
          subheader={review.relative_time_description}
        />
        <CardContent>
          <StarRatings
            rating={review.rating}
            starDimension="20px"
            starSpacing="5px"
            starRatedColor="orange"
            numberOfStars={5}
            name={review.author_name}
          />
          <Typography
            variant="subtitle2"
            color="secondary"
            style={{
              height: "320px",
              marginTop: "10px",
              align: "justify",
              overflow: "auto",
              marginBottom: "40px",
              fontWeight: "500",
            }}
          >
            {review.text}
          </Typography>
          <hr
            style={{
              // color: '#00AEEF',
              // backgroundColor: '#00AEEF',
              color: "grey",
              backgroundColor: "grey",
              height: 2,
              width: "50%",
            }}
          />
        </CardContent>
      </Card>
    );
  };

  renderServiceFees = () => {
    const { classes, t } = this.props;

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        style={{
          padding: `60px 0`,
          marginBottom: 60,
          borderBottom: `2px solid white`,
        }}
      >
        <Grid item sm={3} className={styles?.centeredGrid}>
          {this.renderServiceCard(75)}
        </Grid>

        <Grid item sm={1}>
          <Typography variant="display3" color="inherit" align="center">
            {t("or")}
          </Typography>
        </Grid>

        <Grid item sm={3} className={styles?.centeredGrid}>
          {this.renderServiceCard(100)}
        </Grid>

        <Grid item sm={1}>
          <Typography variant="display3" color="inherit" align="center">
            {t("or")}
          </Typography>
        </Grid>

        <Grid item sm={3} className={styles?.centeredGrid}>
          {this.renderServiceCard(125)}
        </Grid>

        <Grid item sm={3}>
          <Typography variant="title" color="inherit" align="center">
            {t("serviceCall.payLess")}
          </Typography>
        </Grid>
        <Grid item sm={5} />
        <Grid item sm={3}>
          <Typography variant="title" color="inherit" align="center">
            {t("serviceCall.lowerPayment")}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderListItems = (items) => {
    const { classes } = this.props;

    return items.map((benefit, index) => (
      <ListItem key={`benefit-${index}`} sx={{ padding: "12px 24px" }}>
        <ListItemIcon>
          <CheckIcon className={styles?.listIcon} />
        </ListItemIcon>
        <Typography
          className={styles?.listText}
          color="inherit"
          variant="subtitle1"
        >
          {benefit}
        </Typography>
      </ListItem>
    ));
  };

  render() {
    const { t, style, onChoosePlan, classes } = this.props;
    const { places } = this.state;
    var settings = {
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Section
        style={{ ...style, color: "white" }}
        image={BackgroundImage}
        imageMask
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          // spacing={3}
          sx={{ margin: "-8px" }}
        >
          <Grid item xs={12} sm={10} sx={{ padding: "8px" }}>
            <Card className={styles?.titleCard}>
              <CardContent sx={{ padding: "16px 24px" }}>
                <Typography variant="h1" color="white" align="center">
                  {/* {t('serviceCall.title')} */}
                  Customer Reviews
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "1.3125rem", lineHeight: "1.166667em" }}
                  color="white"
                  align="center"
                  gutterBottom
                >
                  What Our Homeowners Say About Us.
                </Typography>
                <div ref={this.myRef}></div>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
            style={{
              padding: `60px 0`,
              marginBottom: 60,
              borderBottom: `2px solid white`,
            }}
          >
            <Grid item sm={12} md={10} className={styles.sliderContainer}>
              <Slider {...settings}>
                {places.map((place) => {
                  return (
                    <div key={place.author_name}>
                      {this.renderServiceCard(place)}
                    </div>
                  );
                })}
              </Slider>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h1" color="inherit" align="center">
              {t("repairCosts.title")}
            </Typography>
            <Typography
              variant="subtitle1"
              color="inherit"
              align="center"
              style={{ marginBottom: 24 }}
            >
              {t("repairCosts.subtitle")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <List>{this.renderListItems(t("repairCosts.benefits"))}</List>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            spacing={2}
            sx={{ margin: "-8px", padding: "8px" }}
          >
            {this.renderCosts(t("repairCosts.costs"))}
          </Grid>

          <Grid item xs={12} style={{ marginTop: 32 }}>
            <Typography variant="subtitle1" color="inherit" align="center">
              {t("repairCosts.planNow")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} style={{ marginTop: 32 }}>
            <Button color="white" onClick={onChoosePlan}>
              {t("choosePlan")}
            </Button>
          </Grid>
        </Grid>
      </Section>
    );
  }
}

// const styles = (theme) => ({
//   card: {
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     background: "rgba(255, 255, 255, 0.85)",
//   },
//   cardMedia: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     height: "100%",
//   },
//   cardIcon: {
//     padding: `0 24px`,
//     color: theme.palette.secondary.main,
//     fontSize: theme.spacing(8),
//   },
//   cardContent: {
//     width: "65%",
//   },
//   centeredGrid: {
//     display: "flex",
//     justifyContent: "center",
//   },
//   serviceCard: {
//     width: 200,
//     height: "100%",
//   },
//   listIcon: {
//     color: theme.palette.common.white,
//     fontSize: theme.spacing(5),

//     [theme.breakpoints.down("sm")]: {
//       fontSize: theme.spacing(3),
//     },
//   },
//   titleCard: {
//     backgroundColor: transparentize(0.2, theme.palette.primary.main),
//   },
// });

export default compose(
  withTranslation("homeowner")
  // withStyles(styles)
)(RepairCosts);
