import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import styled from '@emotion/styled';

import { getConsumerOrderSummary } from 'store/reducers';

class OrderSummary extends React.Component {
  static defaultProps = {
    order: {}
  };

  renderAddons = () => {
    const {
      order: { addons }
    } = this.props;

    if (!addons || !addons.length) return null;

    const text = addons.join(', ');

    return (
      <React.Fragment>
        <ListSubheader disableGutters>
          <ListItemText
            primary='A La Carte Options'
            primaryTypographyProps={{
              variant: 'title',
              color: 'secondary'
            }}
          />
        </ListSubheader>

        <ListItem disableGutters>
          <ListItemText
            primary={text}
            primaryTypographyProps={{ variant: 'caption' }}
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: "'Roboto','Helvetica','sans-serif'"
            }}
          />
        </ListItem>
      </React.Fragment>
    );
  };

  renderOrderSummary = () => {
    const { discount, totalAmount, addonsPrice, policyInfo, plantype } =
      this.props;
    const annualPolicyPrice =
      policyInfo?.Total_Renewal_Price || totalAmount - addonsPrice || 0;
    const policyPrice =
      plantype === 'monthly'
        ? Number(annualPolicyPrice / 12).toFixed(2)
        : annualPolicyPrice;
    const displayAddonsPrice = addonsPrice || 0;
    const displayTotalAmount = totalAmount || 0;

    return (
      <React.Fragment>
        <ListItem disableGutters>
          <ListItemText
            primary='Policy Price'
            primaryTypographyProps={{
              variant: 'subtitle1',
              color: 'secondary'
            }}
          />
          <ListItemSecondaryAction>
            <ListItemText
              sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              primary={`$${policyPrice}`}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
            primary='A La Carte Options Price'
            primaryTypographyProps={{
              variant: 'subtitle1',
              color: 'secondary'
            }}
          />
          <ListItemSecondaryAction>
            <ListItemText
              sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              primary={`$${addonsPrice}`}
            />
          </ListItemSecondaryAction>
        </ListItem>

        {discount > 0 && (
          <ListItem disableGutters>
            <ListItemText
              sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              primary='Promo Code'
              primaryTypographyProps={{
                variant: 'subtitle1',
                color: 'secondary'
              }}
            />
            <ListItemSecondaryAction>
              <ListItemText
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                primary={`- $${discount}`}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {/* {this.renderFeatures()} */}

        {this.renderAddons()}
      </React.Fragment>
    );
  };

  render() {
    const { discount, totalAmount } = this.props;

    return (
      <StyledCard>
        <CardHeader
          title='Order Summary'
          titleTypographyProps={{ color: 'primary' }}
        />
        <CardContent>
          <List>
            {this.renderOrderSummary()}

            <ListItem disableGutters>
              <ListItemText
                primary='Total'
                primaryTypographyProps={{ variant: 'h3' }}
                style={{ color: 'rgba(0, 0, 0, 0.54' }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  primary={`$${totalAmount - discount}`}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </StyledCard>
    );
  }
}

const mapStateToProps = (state) => ({
  order: getConsumerOrderSummary(state)
});

export default compose(
  withTranslation('homeowner'),
  connect(mapStateToProps)
)(OrderSummary);

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  color: theme.palette.common.white
}));
