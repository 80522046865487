export const reducePlanAddons = (selectedAddons = {}, addons = []) =>
  Object.keys(selectedAddons)
    .filter((key) => selectedAddons[key])
    .map((key) => addons.find((addon) => addon.label === key))
    .reduce((total, addon) => total + addon.price, 0);

export const reducePlanFeatures = (
  selectedFeatures = {},
  features = [],
  base
) => {
  const reduceFeature = (total, key) => {
    const feature = features.find((f) => f.label === key);
    return feature ? (total += feature.price) : total;
  };

  const keys = Object.keys(selectedFeatures).filter(
    (key) => selectedFeatures[key]
  );

  if (keys.length < 10) return 0;

  // calculate the first 10 selected features and if it's less than the plan
  // minimum then we'll use the plan minimum and add additional features to the
  // plan minimum. That way there's not a scenerio where they can select 12 options
  // without it ever changing price
  const first10 = keys.slice(0, 10).reduce(reduceFeature, 0);
  // const first10 = keys.slice(0, 10).reduce(reduceFeature, 0);

  const total = keys
    .slice(10)
    .reduce(reduceFeature, first10 < base && keys.length < 11 ? base : first10);
  return total < base ? base : total;
};

export const calculatePlanTotal = (
  base,
  features = 0,
  addons = 0,
  serviceFee = 75,
  squareFootage
) => {
  const planMinimium = base > features ? base : features;
  const square_footage = squareFootage !== undefined ? squareFootage : 0;
  // prettier-ignore
  const planTotal = (planMinimium + (square_footage * 85 ) ) + addons;
  // const sqFtPremium = (squareFootage - 5000) * 0.13;

  // const serviceFeeDiscount = Math.floor((serviceFee - 75) / 5);

  // return (planTotal - serviceFeeDiscount * 12 + sqFtPremium).toFixed(2);
  return planTotal.toFixed(2);
};
