import React, { Component } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid } from "@mui/material";

import BackgroundImage from "assets/images/house.jpg";
import Section from "components/common/section";

import { getIsSignedIn } from "store/reducers";
import { loadWarrantyClaims, loadWarrantyPlans } from "store/actions/profile";
import ProfileService from "services/profile";
import ZcrmService from "services/zohocrm";

import ClaimDetails from "./claim-details";
import PolicyDetails from "./policy-detail";
import ClaimHistory from "./claim-history";
import PlanDetail from "./plan-detail";
import Profile from "./profile";
// import { clearToken } from "helpers/utils";

class HomeownerAccount extends Component {
  state = { policy: {}, claim: {}, email: "" };

  componentDidMount() {
    const { auth, isSignedIn, history } = this.props;
    this.setState({ email: auth.email });
  }

  loadAllPolicies = () => {
    let access_token = ZcrmService.getToken();
    access_token.then((res) => {
      let allPoliies = ZcrmService.getAllPolicies(
        res.access_token,
        this.state.email
      );
      allPoliies
        .then((response) => {
          const policies = response.details.output;
          const allPolicyArray = "[" + policies + "]";
          this.setState({ policy: allPolicyArray });
        })
        .catch((error) => {
          // if (error.name === "StatusCodeError") clearToken();
          return error;
        });
    });
  };

  loadData = () => {
    let access_token = ZcrmService.getToken();
    access_token.then((res) => {
      // let searchPolicies = ZcrmService.getPolicies(res.access_token, this.state.email);
      // searchPolicies.then(response => {
      //   localStorage.setItem('policy_details', JSON.stringify(response.data));
      //   this.setState({policy:response.data})
      // }).catch((error) => {
      //   return (error)
      // })

      let searchClaims = ZcrmService.getClaims(
        res.access_token,
        this.state.email
      );
      searchClaims
        .then((response) => {
          // if (response.name === "StatusCodeError") clearToken();
          localStorage.setItem("claim_details", JSON.stringify(response.data));
          this.setState({ claim: response.data });
        })
        .catch((error) => {
          // if (error.name === "StatusCodeError") clearToken();
          return error;
        });
    });
  };
  loadProfile = () => {
    const {
      auth: { uid },
      loadClaims,
      loadPlans,
    } = this.props;

    loadClaims(ProfileService.getUserClaims(uid));
    loadPlans(ProfileService.getUserPlans(uid));
  };

  render() {
    const { auth, claims, plans } = this.props;

    return (
      <Section image={BackgroundImage} imageMask fullHeight>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Profile
              {...auth}
              isSignedIn={this.props.isSignedIn}
              loadProfile={this.loadProfile}
              navigate={this.props.navigate}
              loadAllPolicies={this.loadAllPolicies}
              loadData={this.loadData}
            />
          </Grid>
          <Grid item xs={12}>
            {this.state.policy ? (
              <PolicyDetails
                navigate={this.props.navigate}
                plans={this.state.policy}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <ClaimDetails claims={this.state.claim} />
          </Grid>
          <Grid item xs={12}>
            <PlanDetail plans={plans} navigate={this.props.navigate} />
          </Grid>
          <Grid item xs={12}>
            <ClaimHistory claims={claims} />
          </Grid>
        </Grid>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isSignedIn: getIsSignedIn(state),
  claims: state.profile.claims,
  plans: state.profile.plans,
});

const mapDispatchToProps = (dispatch) => ({
  loadPlans: (promise) => dispatch(loadWarrantyPlans(promise)),
  loadClaims: (promise) => dispatch(loadWarrantyClaims(promise)),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(HomeownerAccount);
