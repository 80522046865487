export default {
  agents: {
    pageTitle: 'Agents',

    header: `Need greater support from your Real Estate Ecosystem?`,
    subHeader: `We're here to do just that!`,

    labels: [
      'Open House Flyer Request',
      'Agent Coaching',
      // 'Buyer / Seller Leads',
      // 'Drone Photography',
      'Agent Support Survey',
    ],
  },
};
