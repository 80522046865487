import { get } from "services/firebase";

export default class ProfileService {
  static getUserPlans(uid) {
    try {
      return get(`users/${uid}/orders`);
    } catch (error) {
      error = error;
    }
  }

  static getUserClaims(uid) {
    try {
      return get(`users/${uid}/claims`);
    } catch (error) {
      console.log(error);
    }
  }

  static getPayment(uid, paymentId) {
    try {
      return get(`users/${uid}/payments/${paymentId}`);
    } catch (error) {
      console.log(error);
    }
  }
}
