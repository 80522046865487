import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm, Field, SubmissionError } from "redux-form";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Radio,
  FormControlLabel,
  Grid,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import * as Validator from "helpers/validators";
import { renderTextField, renderRadioGroup } from "helpers/form";
import Button from "components/common/button";
import { getConsumerPlanTotal } from "store/reducers";
import ConfirmDialog from "./confirm-dialog";
import PaymentService from "services/payment";
import { reportError } from "store/actions/app";
// import AuthPay from "services/authorize";
const mapErrorsToFields = new Map([
  ["card.expmonth", "expMonth"],
  ["card.expyear", "expYear"],
  ["card.cvc", "cvc"],
  ["card.number", "number"],
]);

class PaymentMethod extends React.Component {
  state = { token: null, open: false, recurring: null };

  createToken = async (values) => {
    const { value, errors } = await PaymentService.createToken(values);
    console.log("new token created:::::::::::::", values);
    console.log(":::::::::::::", JSON.stringify(values));
    if (errors) {
      errors.forEach((err) => {
        this.props.reportError(err.moreInfo);

        throw new SubmissionError({
          [mapErrorsToFields.get(err.detail)]: err.moreInfo,
          _error: err.moreInfo,
        });
      });
    } else if (!value) {
      const error = "error validating credit card";
      this.props.reportError(error);
      throw new SubmissionError({ _error: error });
    } else {
      this.setState({ token: value, recurring: values.recurring === "true" });
    }
  };

  handleOpen = (values) => {
    this.setState({ open: true, recurring: this.props.plantype === "monthly" });
  };
  handleCheckout = () => {
    const { token, recurring } = this.state;
    this.props.onCheckout(
      token,
      recurring,
      this.props.paymentform?.values,
      this.props.reportError
    );
    this.setState({ token: null, recurring: null, open: false });
  };

  render() {
    const {
      t,
      disabled,
      total,
      handleSubmit,
      service_call_fee,
      plantype,
      discount,
      invalid,
    } = this.props;
    const { token, recurring } = this.state;

    const amount =
      service_call_fee !== undefined
        ? service_call_fee
        : plantype === "monthly"
        ? total.monthly - discount > 0
          ? (total.monthly - Number(discount)).toFixed(2)
          : total.monthly
        : total.annual - discount > 0
        ? total.annual - discount
        : total.annual;
    return (
      <Card style={{ height: "100%" }}>
        <form onSubmit={handleSubmit(this.handleOpen)}>
          <ConfirmDialog
            total={amount}
            open={this.state.open}
            onCancel={() => this.setState({ token: null, open: false })}
            onConfirm={this.handleCheckout}
            callFee={service_call_fee}
          />

          <CardHeader
            title="Payment Method"
            titleTypographyProps={{ color: "primary" }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="name"
                  component={renderTextField}
                  label={t("common:nameOnCard")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="number"
                  component={renderTextField}
                  label={t("common:cardNumber")}
                  validate={[Validator.isRequired, Validator.luhnCheck]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="expMonth"
                  type="number"
                  component={renderTextField}
                  label={t("common:expMonth")}
                  validate={[Validator.isRequired, Validator.expiryMonth]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="expYear"
                  type="number"
                  component={renderTextField}
                  label={t("common:expYear")}
                  validate={[Validator.isRequired, Validator.expiryYear]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="cvc"
                  component={renderTextField}
                  label={t("common:cvc")}
                  validate={[Validator.isRequired, Validator.validCvv]}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="streetAddress"
                  component={renderTextField}
                  label={t("common:street")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="city"
                  component={renderTextField}
                  label={t("common:city")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="region"
                  component={renderTextField}
                  label={t("common:state")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="postalCode"
                  component={renderTextField}
                  label={t("common:postal")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="recurring" component={renderRadioGroup}>
                  <FormControlLabel
                    value="true"
                    label={`Billed Monthly (x 12): $${total.monthly}`}
                    labelPlacement="end"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    value="false"
                    label={`Single Payment: $${total.annual}`}
                    labelPlacement="end"
                    control={<Radio color="primary" />}
                  />
                </Field>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "8px 12px",
            }}
          >
            {service_call_fee === undefined && (
              <Button
                fullWidth={false}
                component={Link}
                to={`/homeowner/order/build`}
                style={{
                  padding: "8px 24px",
                  margin: "0 4px",
                  color: "#fff",
                  textTransform: "unset",
                }}
              >
                {t("goBack")}
              </Button>
            )}
            <Button
              fullWidth={false}
              disabled={disabled || this.props.submitting}
              type="submit"
              style={{ padding: "8px 24px", margin: "0 4px", color: "#fff" }}
              sx={{
                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.26) !important",
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                textTransform: "unset",
              }}
            >
              {t("checkout")}
            </Button>
          </CardActions>
        </form>

        {this.props.errmsg && (
          <div
            style={{
              margin: "0 10px",
              padding: "10px",
              backgroundColor: "#f44336",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            <ErrorIcon />
            <div style={{ marginLeft: "10px", overflow: "hidden" }}>
              {this.props.errmsg}
            </div>
          </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  total: getConsumerPlanTotal(state),
  paymentform: state.form["payment-method"],
});

export default compose(
  withTranslation("homeowner"),
  reduxForm({
    form: "payment-method",
    initialValues: { recurring: "false" },
  }),
  connect(mapStateToProps, { reportError })
)(PaymentMethod);
