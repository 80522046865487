import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { reset } from "redux-form";

import ConsumerService from "services/consumer";
import {
  loadPlanFeatures,
  loadPlanOptions,
  loadPlanAddons,
  setSelectedPlan,
  setServiceCallFee,
  setplantype,
} from "store/actions/consumer";
import {
  getConsumerPlanFeatures,
  getConsumerPlanOptions,
  getConsumerPlanAddons,
  getConsumerPlanTotal,
  getConsumerFeatureCount,
  getServiceCallFee,
  getConsumerOrder,
  getplantype,
} from "store/reducers";
import Section from "components/common/section";

import FeatureList from "./feature-list";
import SimplePlan from "./simple-plan";
import CustomPlan from "./custom-plan";
import Addons from "./addons";
import ServiceCallOptions from "./service-call-options";
import PlanTotal from "./plan-total";
import FAQ from "./faq";

export class PlanBuilder extends React.Component {
  state = { houseSize: this.props.location?.search.split("=")[1] };
  componentDidMount() {
    this.props.loadPlanFeatures(ConsumerService.getPlanFeatures());
    this.props.loadPlanOptions(ConsumerService.getPlanOptions());
    this.props.loadPlanAddons(ConsumerService.getPlanAddons());
    this.setState({ houseSize: this.props.location?.search.split("=")[1] });
  }

  handlePlanSelect = (plan, index) => () => {
    this.props.setSelectedPlan(plan, index);

    if (plan === "kingkong") {
      this.props.reset("homeowner-custom-plan");
    }
  };
  gettype = (type) => {
    this.props.setplantype(type);
  };

  handleServiceCall = (price) => {
    this.props.setServiceCallFee(price);
  };

  handleCheckout = () => this.props.navigate("/homeowner/order/checkout");

  render() {
    const {
      features,
      plans,
      addons,
      total,
      featureCount,
      serviceCallFee,
      t,
      selectedPlan,
    } = this.props;
    const [simplePlan, customPlan] = plans;
    return (
      <StyledSection>
        <Grid container spacing={2} sx={{ margin: "-8px" }}>
          <Grid item xs={6} sm={4} sx={{ padding: "8px !important" }}>
            <FeatureList features={features} />
          </Grid>
          <Grid item xs={3} sm={2}>
            <SimplePlan
              plan={simplePlan}
              features={features}
              selected={selectedPlan === "kingkong"}
              onSelect={this.handlePlanSelect("kingkong", 0)}
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <CustomPlan
              plan={customPlan}
              features={features}
              selected={selectedPlan === "custom"}
              onSelect={this.handlePlanSelect("custom", 1)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            container
            spacing={2}
            sx={{ padding: "8px", margin: "-8px" }}
          >
            <Grid item xs={12}>
              <PlanTotal
                onSubmit={this.handleCheckout}
                annualPrice={total.annual}
                monthlyPrice={total.monthly}
                disabled={featureCount < 10}
                subscriptionType={this.gettype}
              />
            </Grid>
            <Grid item xs={12}>
              <ServiceCallOptions
                value={serviceCallFee}
                onChange={this.handleServiceCall}
              />
            </Grid>
            <Grid item xs={12}>
              <Addons items={addons} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {t("minimumOptions")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FAQ />
          </Grid>
        </Grid>
      </StyledSection>
    );
  }
}

const StyledSection = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.text.primary,
  overflowY: "scroll",
}));

const mapStateToProps = (state) => ({
  selectedPlan: getConsumerOrder(state).planName,
  plans: getConsumerPlanOptions(state),
  features: getConsumerPlanFeatures(state),
  addons: getConsumerPlanAddons(state),
  total: getConsumerPlanTotal(state),
  featureCount: getConsumerFeatureCount(state),
  serviceCallFee: getServiceCallFee(state),
  plantype: getplantype(state),
});

const mapDispatchToProps = {
  loadPlanFeatures,
  loadPlanOptions,
  loadPlanAddons,
  setSelectedPlan,
  setServiceCallFee,
  setplantype,
  reset,
};

export default compose(
  withTranslation("homeowner"),
  connect(mapStateToProps, mapDispatchToProps)
)(PlanBuilder);
