import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Register from "./register";
import SignIn from "./sign-in";
import Reset from "./reset";
import SignOut from "./sign-out";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Routes>
      <Route path={`register`} element={<Register location={location} />} />
      <Route path={`signin`} element={<SignIn />} />
      <Route path={`reset`} element={<Reset />} />
      <Route path={`signout`} element={<SignOut navigate={navigate} />} />
    </Routes>
  );
};
