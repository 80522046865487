import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { reduxForm, Field } from "redux-form";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";

import Button from "components/common/button";
import {
  getIsSignedIn,
  getUserDisplayName,
  getUserEmail,
} from "store/reducers";
import { renderTextField } from "helpers/form";
import withRouter from "helpers/withRouters";

class PlanHolder extends React.Component {
  saveCart = () => {};

  handleAuthWithRedirect = (pathname) => {
    const { history, path } = this.props;

    this.props.navigate(pathname, {
      state: { type: "orderplan" },
      referrer: path,
    });
  };

  handleSignin = () => {
    this.saveCart();

    this.handleAuthWithRedirect("/auth/signin");
  };

  handleRegistration = () => {
    this.saveCart();

    this.handleAuthWithRedirect("/auth/register");
  };

  render() {
    const { t, isSignedIn, path } = this.props;

    return (
      <Card style={{ height: "100%" }}>
        <CardHeader
          title={t("warrantyHolder")}
          titleTypographyProps={{ color: "primary" }}
          sx={{ padding: "16px 24px" }}
        />
        <CardContent sx={{ padding: "16px 24px 24px" }}>
          <form>
            <Grid container spacing={2} sx={{ margin: "-8px" }}>
              {isSignedIn && (
                <React.Fragment>
                  <Grid item xs={12} sm={6} sx={{ padding: "8px !important" }}>
                    <Field
                      name="name"
                      label={t("common:name")}
                      component={renderTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ padding: "8px !important" }}>
                    <Field
                      name="email"
                      label={t("common:email")}
                      component={renderTextField}
                      disabled
                    />
                  </Grid>
                </React.Fragment>
              )}

              {!isSignedIn && (
                <React.Fragment>
                  <Grid item>
                    <Button
                      onClick={this.handleSignin}
                      variant="flat"
                      sx={{
                        backgroundColor: "transparent",
                        color: "#00557E",
                        "&:hover": {
                          backgroundColor: "rgba(0, 85, 126, 0.08)",
                        },
                      }}
                    >
                      {t("common:signIn")}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={this.handleRegistration}
                      component={Link}
                      to={{
                        pathname: "/auth/register",
                        state: { referrer: path },
                      }}
                      sx={{ color: "white" }}
                    >
                      {t("common:register")}
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
  name: getUserDisplayName(state),
  email: getUserEmail(state),
  initialValues: {
    name: getUserDisplayName(state),
    email: getUserEmail(state),
  },
});

export default compose(
  connect(mapStateToProps),
  withTranslation("homeowner"),
  withRouter,
  reduxForm({
    form: "homeowner-plan-holder",
    destroyOnUnmount: false,
    enableReinitialize: true,
  })
)(PlanHolder);
