import { LOAD_AGENT_DATA, SET_PREFERRED_PLAN } from '../actions/data';

const INITIAL_STATE = {
  agents: [],
  preferredPlan: null,
  planTotal: 0
};

// selectors
export const getAgents = state => state.agents;
export const getPreferredPlan = state => state.preferredPlan;
export const getPlanTotal = state => state.planTotal;

// reducers
const actionHandlers = new Map([
  [LOAD_AGENT_DATA, handleLoadAgents],
  [SET_PREFERRED_PLAN, handleSetPreferredPlan]
]);

function handleLoadAgents(state, action) {
  const data = action.payload.data;
  const agents = data ? Object.values(data) : [];

  return { ...state, agents };
}

function handleSetPreferredPlan(state, action) {
  const data = action.payload.data;
  const planTotal = parseFloat(data.amount);

  return data ? { ...state, preferredPlan: data.plan, planTotal } : state;
}

const DataReducer = (state = INITIAL_STATE, action = { type: null }) =>
  actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;

export default DataReducer;
