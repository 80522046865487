import React from 'react';
import i18next from 'i18next';
import styled from '@emotion/styled'

import EnglishPolicy from './english';
import SpanishPolicy from './spanish';
import Section from 'components/common/section';

export default () => {
  const isEnglish = i18next.language === 'en';

  return (
    <StyledSection style={{ color: '#000' }}>
      {isEnglish && <EnglishPolicy />}
      {!isEnglish && <SpanishPolicy />}
    </StyledSection>
  );
};

const StyledSection = styled(Section)(({ theme }) => ({
  fontColor: theme.palette.text.main,
  paddingTop: 0,
  h1: {
    ...theme.typography.display2,
    color: theme.palette.primary.main
  },
  p: {
    ...theme.typography.body1
  },
  li: {
    ...theme.typography.body1,
    fontWeight: 'bold'
  }
}));
