import React from "react";
// import { withStyles } from '@mui/material/styles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BuildIcon from "@mui/icons-material/Build";
import EmailIcon from "@mui/icons-material/Email";
import PeopleIcon from "@mui/icons-material/People";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { Feed } from "@mui/icons-material";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import * as Styled from "./styled";

const MobileMenu = ({ t, anchor, open, onClose }) => (
  <Menu onClick={onClose} anchorEl={anchor} open={open} onClose={onClose}>
    <MenuItem>
      <Styled.DarkLink to="/plans/start">
        <Styled.IconWrapper>
          <AssignmentIcon className="dark" />
        </Styled.IconWrapper>
        {t("navigation.orders")}
      </Styled.DarkLink>
    </MenuItem>
    <Divider />
    <MenuItem>
      <Styled.DarkLink to="/claims">
        <Styled.IconWrapper>
          <BuildIcon className="dark" />
        </Styled.IconWrapper>
        {t("navigation.claims")}
      </Styled.DarkLink>
    </MenuItem>
    <Divider />
    <MenuItem>
      <Styled.DarkLink to="/homeowner">
        <Styled.IconWrapper>
          <PeopleIcon className="dark" />
        </Styled.IconWrapper>
        {t("navigation.homeowner")}
      </Styled.DarkLink>
    </MenuItem>
    <Divider />
    <MenuItem>
      <Styled.DarkLink to="/maintenance">
        <Styled.IconWrapper>
          <MapsHomeWorkIcon className="dark" />
        </Styled.IconWrapper>
        {t("navigation.maintenance")}
      </Styled.DarkLink>
    </MenuItem>
    <Divider />
    <MenuItem>
      <Styled.DarkLink to="/about">
        <Styled.IconWrapper>
          <PeopleIcon className="dark" />
        </Styled.IconWrapper>
        {t("navigation.aboutus")}
      </Styled.DarkLink>
    </MenuItem>
    <Divider />
    <MenuItem>
      <Styled.DarkLink to="/contact">
        <Styled.IconWrapper>
          <EmailIcon className="dark" />
        </Styled.IconWrapper>
        {t("navigation.contact")}
      </Styled.DarkLink>
    </MenuItem>
    <Divider />
    <MenuItem>
      <Styled.DarkLink to="/brochure">
        <Styled.IconWrapper>
          <Feed className="dark" />
        </Styled.IconWrapper>
        {t("navigation.brochure")}
      </Styled.DarkLink>
    </MenuItem>
  </Menu>
);

const styles = {};

export default compose(
  // withStyles(styles),
  withTranslation("common")
)(MobileMenu);
