import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  ListItemText,
  ListItem,
  List,
  Grid,
} from "@mui/material";
import {showFormattedDate} from "../../../helpers/utils";

class PlanDetails extends Component {
  parseObjectToString = (object) => {
    const values = Object.values(object);

    return values.join(", ");
  };

  renderPlans = () => {
    const { plans } = this.props;
    if (Object.keys(plans).length > 0) {
      const planIds = Object.keys(plans);

      return planIds.map((id) => {
        const plan = plans[id];
        // const { monthly, annual } = plan.total ? plan.total : {};
        // const cost = plan.isSubscription ? monthly : annual;
        return (
          <Grid item xs={12} sm={6} key={id}>
            <Paper key={id} elevation={1} style={{ height: "100%" }}>
              <List>
                {/* <ListItem>
                  <ListItemText primary="Policy #" secondary={plan.id} />
                </ListItem> */}
                <ListItem>
                  <ListItemText
                    primary="Plan Type"
                    secondary={plan.plan.replace(/_/g, " ")}
                  />
                </ListItem>
                {plan.property && (
                  <ListItem>
                    <ListItemText
                      primary="Covered Property"
                      secondary={
                        plan.property.unit +
                        " " +
                        plan.property.street +
                        ", " +
                        plan.property.city +
                        ", " +
                        plan.property.state +
                        " - " +
                        plan.property.postal
                      }
                    />
                  </ListItem>
                )}

                {plan.options && (
                  <ListItem>
                    <ListItemText
                      primary="Details Of Coverage"
                      secondary={plan.options.map((option) => (
                        <li>{option.replace(/_/g, " ")}</li>
                      ))}
                    />
                  </ListItem>
                )}
                {/* <ListItem>
                  <ListItemText primary="Service Call Fee" secondary={`$${plan.serviceCallFee}`} />
                </ListItem> */}
                {plan.date&& (
                    <ListItem>
                      <ListItemText
                          primary="Effective Date"
                          secondary={plan.date?showFormattedDate(plan.date):"---"}
                      />
                    </ListItem>
                )}
                {plan.closingDate && (
                    <ListItem>
                      <ListItemText
                          primary="Expiration Date"
                          secondary={plan.closingDate?showFormattedDate(plan.closingDate):"---"}
                    />
                  </ListItem>
                )}
                {plan.promoCode ? (
                  <ListItem>
                    <ListItemText
                      primary="Promo Code"
                      secondary={plan.promoCode}
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                {plan.discount ? (
                  <ListItem>
                    <ListItemText
                      primary="Order Discount"
                      secondary={`$${plan.discount}`}
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                <ListItem>
                  <ListItemText
                    primary="Cost"
                    secondary={`$${plan.orderAmount}`}
                  />
                </ListItem>

                {plan.isSubscription && (
                  <ListItem>
                    <ListItemText
                      primary="Recurring Payment"
                      secondary="Monthly"
                    />
                  </ListItem>
                )}

                {plan.features && (
                  <ListItem>
                    <ListItemText
                      primary="Covered Items"
                      secondary={this.parseObjectToString(plan.features)}
                    />
                  </ListItem>
                )}

                {plan.addons && (
                  <ListItem>
                    <ListItemText
                      primary="Addons"
                      secondary={this.parseObjectToString(plan.addons)}
                    />
                  </ListItem>
                )}
              </List>
            </Paper>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12} sm={12}>
          <Paper elevation={0} style={{ height: "100%" }}>
            <List>
              <ListItem>
                <ListItemText primary="No Previous Plans to display" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      );
    }
  };

  render() {
    return (
      <Card>
        <CardHeader title="Home Warranty Plans" />
        <CardContent>
          <Grid container spacing={2}>
            {this.renderPlans()}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default PlanDetails;
