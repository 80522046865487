import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withRouter from "helpers/withRouters";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { withTranslation } from "react-i18next";
import { reduxForm, Field } from "redux-form";

import * as Validator from "helpers/validators";
import { renderTextField } from "helpers/form";
import { getIsSignedIn } from "store/reducers";
import { resetPassword } from "store/actions/auth";
import Button from "components/common/button";
import AuthService from "services/auth";
import * as Styled from "../styled";

class ResetPassword extends React.Component {
  state = {
      verifyEmailInput: false
  };
  onEmailInputChange = (response) => {
      this.setState({verifyEmailInput: Boolean(response.target.value)});
  };

  componentDidMount() {
    this.getRedirectResult();
  }

  getRedirectResult = () => {
    const { resetPassword } = this.props;
      resetPassword(AuthService.getOAuthResults());
  };

  handleEmailReset = ({email}) => {
    const {resetPassword, navigate} = this.props;
    const resetPasswordResponse = AuthService.resetPassword(email);

    resetPasswordResponse
        .then((data) => {
          console.log("resetPasswordResponse data", data);
          // resetPassword({"message": "Please check your email to reset password"});
          alert("Please check your email to reset password");
          navigate("/auth/signin");
        })
        .catch((error) => {
              console.log("resetPasswordResponse error", error);
            }
        );
    resetPassword(resetPasswordResponse);
  };


  render() {
    const { t, handleSubmit, isSignedIn, location } = this.props;
    return (
      <Styled.Container>
        <Styled.Card>
          <CardHeader
            classes={{ root: "card-header" }}
            title={`${t("nameShort")}: ${t("homeowners")}`}
            subheader={t("resetPasswordInfo")}
            style={{ padding: "16px 24px" }}
          />
          <CardContent>
            <form onSubmit={handleSubmit(this.handleEmailReset)}>
              <Grid container spacing={2} justify="space-around">
                <Grid item xs={12}>
                  <Field
                    name="email"
                    label={t("email")}
                    onChange={this.onEmailInputChange}
                    component={renderTextField}
                    validate={[Validator.isRequired, Validator.isEmail]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    sx={{
                        "&.Mui-disabled": {
                            backgroundColor: "#cccccc",
                            border: "1px solid #999999",
                            color: '#666666'
                        },
                    }}
                    disabled={!this.state.verifyEmailInput}
                    style={{
                        marginTop: 20,
                        fontWeight: 'bold',
                        color: "white"
                    }}
                  >
                    {t("resetPassword")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Styled.Card>
      </Styled.Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
});

export default compose(
  withTranslation(),
  reduxForm({
    form: "resetPassword",
  }),
  connect(mapStateToProps, { resetPassword }),
  withRouter
)(ResetPassword);
