import Icon1 from '@mui/icons-material/Home';
import Icon2 from '@mui/icons-material/SupervisorAccount';
// import Icon3 from '@mui/icons-material/Phone';
// import Icon3 from '@mui/icons-material/AirplanemodeActive';
// import Icon4 from '@mui/icons-material/ImportContacts';
import Icon5 from '@mui/icons-material/LiveHelp';
// import Drone from 'assets/images/drone.svg';

export default locale => ({
  services: {
    en: [
      'https://docs.google.com/forms/d/e/1FAIpQLSeX9qVJf6OlIdbIdAMt5RUKPL-FxgQ9-QMlGkYc9JRYBHVZog/viewform?usp=sf_link',
      'https://docs.google.com/forms/d/e/1FAIpQLSeuUedAEjOMmm34M5G34mH-nQU1GhlA1YxWmXD8riM89Mb5GA/viewform?usp=sf_link',
      // 'https://docs.google.com/forms/d/e/1FAIpQLSf9BADUO5TaFSH9eHupU1GZUrj_2cr31d5GymuNY7iGx3_uQg/viewform?usp=sf_link',
      // 'https://docs.google.com/forms/d/e/1FAIpQLSfEXKmSCiPjE3IW-1t5ms7LCOaRvE7hGOHibj9E0xPOZF3ToA/viewform?usp=sf_link',
      // 'https://docs.google.com/forms/d/e/1FAIpQLSdNJFF67EHGQhZDdcyqLApq6Gf9xlAJFTTBcxjZRJXt-l8pkA/viewform?usp=sf_link',
      'https://docs.google.com/forms/d/e/1FAIpQLScqdhKGErOxWy4rfgMhMekz76lmWwlf1Cn7evPHRjMT8QOmgQ/viewform?usp=sf_link',
    ],
    es: [
      'https://docs.google.com/forms/d/e/1FAIpQLScs4q16NnavSTzRboYToPRYp12wWoOT5dobsb8lWwie-B8Ecw/viewform?usp=sf_link',
      // 'https://docs.google.com/forms/d/e/1FAIpQLSfsZroeJSnAwsbzwwq6E1gKFxeOig5WWr5WGdNSMghRJ_jBbg/viewform?usp=sf_link',
      'https://docs.google.com/forms/d/e/1FAIpQLSdGGuA3GTVdi-96wIk0TtfDtaDBv7922ol4B7whbcBTebQi-g/viewform?usp=sf_link',
      // 'https://docs.google.com/forms/d/e/1FAIpQLSc2v0YIF8HyM9ljtc5kEQf23o87KzlgtysB3fY-o6vVU-tkvA/viewform?usp=sf_link',
      'https://docs.google.com/forms/d/e/1FAIpQLSdDFY5273wTarD9ndKXZteb31evZisKV5lc2zC7JkRwgq56VA/viewform?usp=sf_link',
    ],
  }[locale],

  icons: {
    // en: [Icon1, Icon2, Icon3, Icon4, Icon5],
    // es: [Icon1, Icon3, Icon4, Icon5],
    en: [Icon1, Icon2, Icon5],
    es: [Icon1, Icon2, Icon5],
  }[locale],
});
