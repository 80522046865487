import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

import Section from 'components/common/section';
import PaymentService from 'services/payment';
import DataService from 'services/data';
import {
  submitConsumerOrder,
  submitCharge,
  submitSubscription
} from 'store/actions/data';
import { clearCart } from 'store/actions/consumer';
import {
  getConsumerOrderSummary,
  getIsSignedIn,
  getplantype,
  getUserEmail,
  getUserPhone,
  getUserDisplayName
} from 'store/reducers';

import SuccessDialog from './success-dialog';
import PaymentMethod from './payment-method';
import PlanHolder from './plan-holder';
import ApplyCoupon from './apply-coupon';
import OrderSummary from './summary';
import ZcrmService from 'services/zohocrm';
import { SubmissionError, getFormValues, reset } from 'redux-form';
import AuthPay from 'services/authorize';
// import { clearToken } from "helpers/utils";

const couponOptions = [];
class Checkout extends React.Component {
  state = {
    loading: false,
    showConfirmation: false,
    coupon_value: 0,
    coupon_label: '',
    coupon_list: [],
    contact: [],
    err: ''
  };

  componentDidMount() {
    let access_token = ZcrmService.getToken();
    access_token.then((res) => {
      let search = ZcrmService.searchUser(
        this.props.email,
        res.access_token,
        'Contacts'
      );
      search
        .then((response) => {
          // if (response.name === "StatusCodeError") clearToken();
          localStorage.setItem(
            'customer_details',
            JSON.stringify(response.data)
          );
          this.setState({ contact: response.data });
        })
        .catch((e) => {
          // if (e.name === "StatusCodeError") clearToken();
        });
    });

    const coupons = DataService.getpromoCodes('homeowner');
    coupons.then((res) => {
      if (Object.keys(res).length > 0) {
        const couponIds = Object.values(res);
        couponIds.map((coupon) => {
          var isPresent = couponOptions.some(function (el) {
            return el.label === coupon.label;
          });
          if (!isPresent) {
            couponOptions.push(coupon);
          }
          return couponOptions;
        });
        this.setState({ coupon_list: couponOptions });
      }
    });
  }

  handleCheckout = async (token, recurring, paymentForm, err) => {
    let values = {
      property: this.props.property,
      custom_plan: this.props.custom_plan,
      addons: this.props.addons,
      email: this.props.email,
      name: this.props.name,
      phone: this.props.phone,
      order: this.props.order,
      plantype: this.props.plantype,
      coupon_amount: this.state.coupon_value,
      coupon_label: this.state.coupon_label,
      values: {
        Buyer_First_Language:
          this.props.property?.Buyer_First_Language || 'English'
      }
    };
    console.info('check out order entry ::::::' + new Date());
    console.log(values.property.bigHouse);
    try {
      const { order, submitCharge, submitConsumerOrder, location } = this.props;
      const { total } = order;
      this.setState({ loading: true });
      const { contact: contacts } = this.state;
      // const amount =
      //   location?.service_call_fee !== undefined
      //     ? location?.service_call_fee
      //     : recurring
      //     ? total.monthly
      //     : total.annual;
      // const chargePromise = PaymentService.charge({
      //   token,
      //   amount,
      //   description: order.plan,
      //   recurring,
      // });
      // console.log("payment token::::::", token);
      // submitCharge(chargePromise);

      // const charge = await chargePromise;
      const {
        expYear,
        expMonth,
        number,
        name,
        cvc,
        streetAddress,
        city,
        region,
        postalCode
      } = paymentForm;
      const amount = recurring
        ? total.monthly - Number(this.state.coupon_value)
        : total.annual - Number(this.state.coupon_value);
      const paymentPromise = AuthPay.payment(
        {
          card_number: number,
          expiry_date: `${expMonth}/${expYear}`,
          card_code: cvc,
          first_name: name,
          last_name: '',
          address: streetAddress,
          city: city,
          state: region,
          zip: postalCode
        },
        amount
      );
      submitCharge(paymentPromise);
      paymentPromise
        .then(async (response) => {
          if (response.status === true) {
            try {
              const res = await ZcrmService.getToken();
              let contact = [];
              if (Array.isArray(contact) === false) {
                contact.data.length > 0
                  ? (values.contact_id = contact.data[0].id)
                  : (values.contact_id = '');
              }
              if (contacts?.length === 0) {
                const generateContact = ZcrmService.generateContact(
                  res.access_token,
                  values
                );
                const data = await generateContact;
                data.data[0].code === 'SUCCESS'
                  ? (values.contact_id = data.data[0].details.id)
                  : (values.contact_id = '');
                const order = await ZcrmService.generateOrder(
                  0,
                  res.access_token,
                  values,
                  1,
                  false,
                  response.data.Transaction_id,
                  amount
                );

                if (!order.data || !order?.data[0]?.code === 'SUCCESS') {
                  AuthPay.refund({
                    data: {
                      card_number: number,
                      amount,
                      transactionId: response.data.Transaction_id,
                      expiry_date: `${expMonth}/${expYear}`
                    }
                  });
                  throw new Error(order.error.message);
                }
              } else {
                values.contact_id = contacts[0].id;
                const order = await ZcrmService.generateOrder(
                  0,
                  res.access_token,
                  values,
                  1,
                  false,
                  response.data.Transaction_id,
                  amount
                );
                if (
                  order?.error?.status ||
                  order?.data[0]?.code !== 'SUCCESS'
                ) {
                  AuthPay.refund({
                    data: {
                      card_number: number,
                      amount,
                      transactionId: response.data.Transaction_id,
                      expiry_date: `${expMonth}/${expYear}`
                    }
                  });
                  throw new Error(order.error.message);
                }
              }
              await submitConsumerOrder(
                DataService.saveConsumerOrder(
                  order,
                  response.data.Transaction_id,
                  recurring
                )
              );
              this.setState({ loading: false });
              console.info(
                'after data service check out order  ::::::' + new Date()
              );
              this.props.navigate('/homeowner/order/confirm', {
                state: { type: 'homeowner' }
              });
            } catch (e) {
              console.log(e);
              this.setState({
                err: 'Order Creation Failed Please try again',
                loading: false
              });
              setTimeout(() => {
                this.setState({ err: '' });
              }, 5000);
            }
          } else {
            const err = response.errors.error_message;
            this.setState({ err: err, loading: false });
            setTimeout(() => {
              this.setState({ err: '' });
            }, 5000);
            this.props.resetPaymentForm();
          }
        })
        .catch((e) => {
          console.log(e.message);
          this.setState({
            err: 'unable to charge the card please try again',
            loading: false
          });
          setTimeout(() => {
            this.setState({ err: '' });
          }, 5000);
          this.props.resetPaymentForm();
        });
      console.info('before data service check out order  ::::::' + new Date());
      // await submitConsumerOrder(
      //   DataService.saveConsumerOrder(order, charge.id)
      // );
      // this.setState({ loading: false, showConfirmation: true });
    } catch (e) {
      console.log(e, 'error');
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ err: '' });
      }, 5000);
      this.props.resetPaymentForm();
    }
  };

  handleConfirmationClose = () => {
    this.setState({ showConfirmation: false });
    this.props.clearCart();
    this.props.navigate('/homeowner/account');
  };

  get_coupon = (coupon) => {
    this.setState({
      coupon_value: coupon.value,
      coupon_label: coupon.label
    });
  };

  render() {
    const { match, order, isSignedIn, location, plantype } = this.props;
    const { showConfirmation, loading, coupon_list } = this.state;
    return (
      <StyledSection>
        <SuccessDialog
          order={order}
          onClose={this.handleConfirmationClose}
          open={showConfirmation}
        />

        <Grid
          container
          spacing={2}
          sx={{ margin: '-8px' }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            container
            spacing={2}
            sx={{ margin: '-8px', padding: '8px !important' }}
          >
            <Grid
              item
              xs={12}
              sx={{ padding: '8px  !important' }}
            >
              <PlanHolder
                navigate={this.props.navigate}
                path={match?.path}
              />
            </Grid>
            {location?.claims === undefined && isSignedIn && (
              <Grid
                item
                xs={12}
                sx={{ padding: '8px  !important' }}
              >
                <ApplyCoupon
                  path={match?.path}
                  options={coupon_list}
                  getcouponamount={this.get_coupon}
                  plantype={plantype}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ padding: '8px  !important' }}
            >
              <PaymentMethod
                onCheckout={this.handleCheckout}
                disabled={!isSignedIn || loading}
                discount={this.state.coupon_value}
                service_call_fee={
                  location?.service_call_fee !== undefined
                    ? location?.service_call_fee
                    : undefined
                }
                plantype={plantype}
                navigate={this.props.navigate}
                errmsg={this.state.err}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ padding: '8px  !important' }}
          >
            <OrderSummary
              discount={this.state.coupon_value}
              address={
                location?.claims !== undefined ? location?.claims : undefined
              }
              service_call_fee={
                location?.service_call_fee !== undefined
                  ? location?.service_call_fee
                  : undefined
              }
              plantype={plantype}
            />
          </Grid>
        </Grid>
      </StyledSection>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
  order: getConsumerOrderSummary(state),
  plantype: getplantype(state),
  name: getUserDisplayName(state),
  email: getUserEmail(state),
  phone: getUserPhone(state),
  property: getFormValues('homeowner-property-info')(state),
  custom_plan: getFormValues('homeowner-custom-plan')(state),
  addons: getFormValues('homeowner-plan-addons')(state)
});

const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart),
  submitCharge: (promise) => dispatch(submitCharge(promise)),
  submitSubscription: (promise) => dispatch(submitSubscription(promise)),
  submitConsumerOrder: (promise) => dispatch(submitConsumerOrder(promise)),
  resetPaymentForm: () => dispatch(reset('payment-method'))
});

export default compose(
  withTranslation('homeowner'),
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout);

const StyledSection = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.text.primary,
  justifyContent: `flex-start`
}));
