export default {
  nameFull: 'SilverBack Home Warranty',
  nameShort: 'SilverBack',

  getStarted: 'Get Started',
  downloadPlans: 'Download Plans',
  getProtected: 'Get Protected',
  goHome: 'Return Home',
  scf_checkout: 'Checkout Service Call Fee',
  contactUs: 'Contact Us',
  contactMessage: '...or send us a message',
  send: 'Send',
  submit: 'Submit',
  continue: 'Continue',
  yes: 'Yes',
  no: 'No',

  navigation: {
    orders: 'Place an Order',
    claims: 'Request Service',
    contact: 'Contact Us',
    agentServices: 'Agent Services',
    signin: 'Sign In',
    resetPassword: 'Reset Password',
    signout: 'Sign Out',
    homeowner: 'Consumer',
    myaccount: 'My Account',
    aboutus: 'About Us',
    brochure: 'Brochure',
    maintenance: 'Home Maintenance'
  },

  street: 'Street Address',
  unit: 'Unit #',
  city: 'City',
  state: 'State',
  postal: 'Zip Code',

  nameOnCard: 'Cardholder Name',
  cardNumber: 'Card Number',
  expMonth: 'Exp Month',
  expYear: 'Exp Year',
  expDate: 'Expiry Date',
  cvc: 'CVV',

  contactInformation: 'Contact Information',
  homeowner: 'Homeowner',
  listingAgent: 'Listing Agent',
  buyerAgent: 'Buyer Agent',
  escrowOfficer: 'Escrow Officer',
  transactionCoordinator: 'Transaction Coordinator',

  buyerInformation: 'Buyer Information',
  sellerInformation: 'Seller Information',
  titleEscrowInformation: 'Title/Escrow Information',

  message: `What do you have to say?`,
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  phoneNumberConsentHelpText:
    'Message and Data rates may apply. Carriers are not liable for delayed or undelivered messages. Text help for help & stop to unsubscribe.',
  phoneNumberConsentMsgText:
    'By checking this box, you are agreeing to receive text messages from SilverBackHW.',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  company: 'Company',
  agentName: 'Agent Name',
  agentEmail: 'Agent Email',
  agentPhone: 'Agent Phone',
  escrowCompany: 'Escrow Company',

  totalDue: 'Total Due',
  plan: 'Plan',
  options: 'Options',
  total: 'Total',
  policy: 'Policy',

  developedBy: 'Developed by',

  pageNotFound: 'Page Not Found: 404',
  pageNotFoundMessage: `Sorry! The page you're looking for can't be found`,

  signIn: 'Sign In',
  resetPassword: 'Reset password',
  resetPasswordInfo: 'Please input email for reset password',
  forgetPasswordMessage: 'Forget password?',
  register: 'Register',
  useExistingAccount: 'or use an existing account',
  alreadyHaveAccount: 'Already have an account?',
  homeowners: 'Homeowners',
  pleaseSignIn: 'Please Sign In',
  createAccount: 'Create Account',
  dontHaveAccount: `Don't have an account?`,
  createNewAccount: 'Create a new account',
  or: 'or',
  validationErrors: {
    NaN: 'Please enter a digit character',
    notInRange: 'quantity is not in available range',
    notInteger: 'quantity should be an integer digit'
  },
  applyCoupon: 'Promo Code',
  invalidCoupon: 'Invalid Promo Code',
  quote: 'Please call in for a quote : 833-750-6400'
};
