import React from 'react';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';

import Button from 'components/common/button';
import * as Styled from './styled';

const OrderConfirmation = ({ open, t, onClose }) => (
  <Styled.Modal open={open} onClose={onClose}>
    <Styled.ModalContent>
      <Typography variant="display3" color="primary">
        {t('plans:order.confirmationTitle')}
      </Typography>
      <Typography variant="title">{t('plans:order.confirmationBody')}</Typography>
      <Button onClick={onClose} color="secondary" style={{ marginTop: 20 }}>
        {t('goHome')}
      </Button>
    </Styled.ModalContent>
  </Styled.Modal>
);

export default withTranslation(['common', 'plans'])(OrderConfirmation);
