import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ErrorIcon from "@mui/icons-material/Error";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import PaymentService from "services/authorize";
import ReCAPTCHA from "react-google-recaptcha";

const AddPaymentMethod = (props) => {
  const { isListingCoverage } = props;
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [prepaidAmount, setPrepaidAmount] = useState("");
  const [transactionError, setTransactionError] = useState(false);
  const [transactionErrorMessage, setTransactionErrorMessage] =
    useState("false");
  const [cardNumberInValid, setCardNumberInValid] = useState(false);
  const [expiryDateInValid, setExpiryDateInValid] = useState(false);
  const [cardCodeInValid, setCardCodeInValid] = useState(false);
  const [firstnameInValid, setfirstNameInValid] = useState(false);
  const [lastNameInValid, setlastNameInValid] = useState(false);
  const [addressInValid, setaddressInValid] = useState(false);
  const [cityInValid, setcityInValid] = useState(false);
  const [stateInValid, setstateInValid] = useState(false);
  const [zipInValid, setzipInValid] = useState(false);
  const [amountInValid, setAmountInValid] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isdisable, setIsDisable] = useState(true);

  useEffect(() => {
    setPrepaidAmount(props.totalAmount);
    props.prepaidAmount(props.totalAmount);
  }, []);

  useEffect(() => {
    const textFieldValues = Object.values(formState.values);
    const typedFieldsCount = textFieldValues.filter((value) => {
      return value.length > 0;
    }).length;
    if (isListingCoverage) {
      setDisabled(typedFieldsCount !== 10);
    } else {
      setDisabled(typedFieldsCount !== 9);
    }
  }, [formState.values]);

  const handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    if (fieldName === "amount") {
      setPrepaidAmount(fieldValue);
      props.prepaidAmount(fieldValue);
    }
    if (fieldName === "card_number") {
      if (fieldValue.length === 16 || fieldValue.length === 15) {
        setCardNumberInValid(false);
      } else {
        setCardNumberInValid(true);
      }
    } else if (fieldName === "expiry_date") {
      if (fieldValue.length === 5) {
        setExpiryDateInValid(false);
      } else {
        setExpiryDateInValid(true);
      }
    } else if (fieldName === "card_code") {
      if (fieldValue.length === 3 || fieldValue.length === 4) {
        setCardCodeInValid(false);
      } else {
        setCardCodeInValid(true);
      }
    } else if (fieldName === "first_name") {
      if (fieldValue.length > 2) {
        setfirstNameInValid(false);
      } else {
        setfirstNameInValid(true);
      }
    } else if (fieldName === "last_name") {
      if (fieldValue.length > 2) {
        setlastNameInValid(false);
      } else {
        setlastNameInValid(true);
      }
    } else if (fieldName === "address") {
      if (fieldValue.length > 2) {
        setaddressInValid(false);
      } else {
        setaddressInValid(true);
      }
    } else if (fieldName === "city") {
      if (fieldValue.length > 2) {
        setcityInValid(false);
      } else {
        setcityInValid(true);
      }
    } else if (fieldName === "state") {
      if (fieldValue.length > 1) {
        setstateInValid(false);
      } else {
        setstateInValid(true);
      }
    } else if (fieldName === "zip") {
      if (fieldValue.length > 4) {
        setzipInValid(false);
      } else {
        setzipInValid(true);
      }
    } else if (fieldName === "amount") {
      if (fieldValue.length > 0) {
        setAmountInValid(false);
      } else {
        setAmountInValid(true);
      }
    }
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const onRecaptcha = (response) => {
    if (response) {
      setIsDisable(!Boolean(response));
    }
  };

  const disablePay = () => {
    return (
      disabled ||
      cardCodeInValid ||
      cardNumberInValid ||
      expiryDateInValid ||
      firstnameInValid ||
      lastNameInValid ||
      addressInValid ||
      cityInValid ||
      stateInValid ||
      zipInValid ||
      isdisable
    );
  };

  const handleSavepayment = () => {
    setTransactionError(false);
    setIsLoading(true);
    let paymentResposne = PaymentService.payment(
      formState.values,
      prepaidAmount
    );
    paymentResposne
      .then((response) => {
        if (response.status === true) {
          props.handlePaymentResponse(response.data.Transaction_id, true);
        } else {
          setTransactionError(true);
          setTransactionErrorMessage(response.errors.error_message);
          // props.handlePaymentResponse('', false)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handlepress = (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    const forwardSlash = 47;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode !== forwardSlash) {
        evt.preventDefault();
      }
    }
  };

  return (
    <div>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader
            sx={{
              padding: {
                xs: "16px",
                sm: "16px 24px",
              },
            }}
            title="Enter Card Details"
          />
          <Divider />
          <CardContent
            sx={{
              padding: {
                xs: "16px",
                sm: "16px 24px",
              },
            }}
          >
            <Grid container>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Credit Card Number"
                  margin="dense"
                  name="card_number"
                  error={cardNumberInValid}
                  helperText={cardNumberInValid ? "Invalid Card Number" : ""}
                  value={formState.values.card_number}
                  onKeyPress={handlepress}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Expiration Date"
                  margin="dense"
                  name="expiry_date"
                  onKeyPress={handlepress}
                  error={expiryDateInValid}
                  helperText={
                    expiryDateInValid
                      ? "Invalid Expiry Date - ( MM/YY)"
                      : "( MM/YY )"
                  }
                  onChange={handleChange}
                  required
                  // variant="outlined"
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  // fullWidth
                  label="CVV"
                  margin="dense"
                  name="card_code"
                  onKeyPress={handlepress}
                  error={cardCodeInValid}
                  helperText={cardCodeInValid ? "Invalid Card Code" : ""}
                  type="password"
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              {/* <Grid item md={12} xs={12}>
                Enter Address 
              </Grid> */}
              <Grid item md={6} xs={12}>
                <TextField
                  // fullWidth
                  label="First Name"
                  margin="dense"
                  name="first_name"
                  error={firstnameInValid}
                  helperText={firstnameInValid ? "First Name is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  // fullWidth
                  label="Last Name"
                  margin="dense"
                  name="last_name"
                  error={lastNameInValid}
                  helperText={lastNameInValid ? "Last Name is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  margin="dense"
                  name="address"
                  error={addressInValid}
                  helperText={addressInValid ? "Address is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  // fullWidth
                  label="City"
                  margin="dense"
                  name="city"
                  error={cityInValid}
                  helperText={cityInValid ? "City is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  // fullWidth
                  label="State"
                  margin="dense"
                  name="state"
                  error={stateInValid}
                  helperText={stateInValid ? "State is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  // fullWidth
                  label="Zip Code"
                  margin="dense"
                  name="zip"
                  onKeyPress={handlepress}
                  error={zipInValid}
                  helperText={zipInValid ? "Zip is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  value={prepaidAmount}
                  onKeyPress={handlepress}
                  // disabled={!isListingCoverage}
                  label="Amount"
                  margin="dense"
                  name="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: !isListingCoverage,
                  }}
                  error={amountInValid}
                  helperText={amountInValid ? "Amount is Required" : ""}
                  onChange={handleChange}
                  required
                  variant="standard"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid
            item
            md={12}
            xs={12}
            style={{ marginTop: "1rem", marginLeft: "1rem" }}
          >
            <ReCAPTCHA
              sitekey="6Lca3k8UAAAAALTNS6xmspck9Cx1nuu6Az_R-3A4"
              onChange={(res) => {
                onRecaptcha(res);
              }}
            />
          </Grid>
          <CardActions sx={{ padding: "8px 12px" }}>
            <Button
              style={{ width: "25%" }}
              color="primary"
              variant="contained"
              disabled={disablePay()}
              onClick={handleSavepayment}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: "38.5px",
                    paddingLeft: "38.5px",
                  }}
                >
                  <CircularProgress
                    size="25px"
                    // color="white"
                    sx={{ color: "#FFF" }}
                  ></CircularProgress>
                </div>
              ) : (
                "Pay"
              )}
            </Button>
            <Button
              color="primary"
              // variant="outlined"
              onClick={() => props.closePaymentForm()}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderBottomColor: "rgba(0, 85, 126, 0.5",
                border: "1px solid rgba(0, 85, 126, 0.5)",
                color: "#00557E",
                borderRadius: "10px",
                minWidth: "64px",
              }}
            >
              Cancel
            </Button>

            {transactionError && (
              <Grid item md={12} xs={12}>
                <div
                  style={{
                    margin: "0 10px",
                    padding: "10px",
                    backgroundColor: "#f44336",
                    borderRadius: "5px",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon />
                  <div style={{ marginLeft: "10px" }}>
                    {transactionErrorMessage}
                  </div>
                </div>
              </Grid>
            )}
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

AddPaymentMethod.propTypes = {
  className: PropTypes.string,
};

export default AddPaymentMethod;
