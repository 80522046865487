export default {
  start: {
    pageTitle: "Plan Options",
    header: "Get Started",
    subHeader: "(For real estate transactions only)",
  },

  order: {
    pageTitle: "New Order",
    header: "Place Your Order",
    confirmationTitle: "Thank You for your order!",
    confirmationBody: `
      Your order has been placed. An invoice will be sent to the
      Title/Escrow Officer and confirmation of payment and coverage
      will be sent out after closing.
    `,
    thankYou: "Thank You!",
    successSubtitle:
      "Your Request Has Been Placed. We Will Be Contacting You To Gather Additional Details, If Required.",
    failedSubtitle: `Your Request Has Not Been Placed. Please Send Mail To ${process.env.REACT_APP_EMAIL_TO} For Further Queries.`,
  },

  policy: {
    confirmationTitle: "Thank You For Renewing The Policy !",
    confirmationBody: `
     Your policy has been renewed
    `,
  },

  listingCoverage: "Listing Coverage",
  variableAmount: "I have this much:",
  mostPopular: "Most Popular Plan",

  orderNow: "Order Now",
  choosePlan: "Choose Plan",
  seePlans: "See Plans",

  placedBy: "Order Placed By:",

  propertyDetails: "Property Details",
  selectManager: "Select Your Area Manager",
  bigHouse: "Is the property over 6000 sq.ft.?",
  isSpanish:"Is Buyer's First Language Spanish ?",
  homeSize: "How many sq.Ft the Home is ?",
  selectHouseSize: "Select House Size",
  estClosingDate: "Estimated Closing Date",
  planOptionTitle: "Plan Options",

  single: {
    title: "Single Family Home / Condo / Townhome / Mobile Home",
    blackback: "Blackback Plan",
    silverback: "Silverback Plan",
    kingKong: "King Kong Plan",
  },
  multi: {
    title: "Multi-Family Homes",
    duplex: "Duplex",
    triplex: "Triplex",
    fourplex: "Fourplex",
  },
  newConstruction: {
    title: "New Construction (Years 2-4)",
    newHome: "Single Family",
    newCondo: "Condo / Townhome",
  },

  addonTitle: "A La Carte Options (Add to any Plan)",
  addonLabels: [
    "Additional Refrigeration",
    "Stand Alone Freezer",
    "2nd Kitchen (per unit)",
    "Washer and Dryer (per set)",
    "Water Softener",
    "Additional Water Heater",
    "Central Vacuum",
    "Swimming Pool & Hot Tub",
    "Water/Gas/Sewage Line Coverage",
    // 'Salt Water Swimming Pool & Hot Tub',
    // 'Freestanding Icemaker',
    "Septic System & Pumping",
    "Grinder Pump/Sewer Ejector",
    "Booster Pump/Sump Pump",
    "Detached Building Coverage",
    "Well Pump",
    "Basement Apartment/Mother-in-law suite",
    "Additional Sq Ft",
    "Pre-Paid Service Call Fee",
    "No-Fault Coverage",
    "AC Protection",
    "Brand For Brand",
    "Sprinkler Timer & System",
    "Boiler System",
    "Buyer Credit",
  ],

  form: {
    title: "Place Your Order",
    comments: "Additional Comments",
    emails: "Additional Email Confirmation",
    emailHelper: "Different from emails listed above, separated by a comma",
    placeOrder: "Place Order",
    payNow: "Pay Now",
    prepay: "Prepay Buyer's Coverage",
    required: "** missing required fields",
  },
};
