import React, { PureComponent } from "react";
import { Button } from "@mui/material";
import { func } from "prop-types";
import { withTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import i18n from "i18n"; // initialized i18next instance
import * as Styled from "./styled";

export class Footer extends PureComponent {
  static propTypes = {
    onLocaleChange: func,
  };

  handleLocaleChange = (locale) => () => {
    const { onLocaleChange } = this.props;

    i18n.changeLanguage(locale);
    if (typeof onLocaleChange === "function") onLocaleChange(locale);
  };

  render() {
    const { t, i18n } = this.props;
    const isEnglish = i18n.language === "en";

    return (
      <Styled.Footer>
        <Styled.FooterContent>
          <div className="info">
            <div className="copyright">&copy; {t("nameFull")}</div>
            <div className="credit">
              {t("developedBy")}{" "}
              <a
                href="https://techjays.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TECHJAYS
              </a>
            </div>
          </div>

          <div className="info">
            <Typography color="inherit" variant="h5" align="center">
              <a href="tel:8337506400">{t("contactUs")}: 833.750.6400</a>
            </Typography>
          </div>

          <div className="info locale">
            <Button
              variant={isEnglish ? "outlined" : "text"}
              color="secondary"
              size="small"
              onClick={this.handleLocaleChange("en")}
            >
              English
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              variant={isEnglish ? "text" : "outlined"}
              color="secondary"
              size="small"
              onClick={this.handleLocaleChange("es")}
            >
              Español
            </Button>
          </div>
        </Styled.FooterContent>
      </Styled.Footer>
    );
  }
}

export default withTranslation()(Footer);
