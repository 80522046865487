import React, { Component } from "react";
import Helmet from "react-helmet";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import cart from "services/shopping-cart";
import { setPreferredPlan } from "store/actions/data";
import BackgroundImage from "assets/images/Kitchen 2 w gorilla.jpg";
import Section from "components/common/section";
import PlanOptions from "./components/plan-options";

class OptionPage extends Component {
  handlePlanSelection = (name, amount) => {
    cart.plan = { name, amount };
    this.props.setPreferredPlan(name, amount);
    // this.props.history.push({
    //   pathname: '/plans/order',
    //   search: `?plan=${name}`
    // })
    this.props.handleNavigation(`/plans/order?plan=${name}`, {
      state: { search: `?plan=${name}` },
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Section image={BackgroundImage}>
        <Helmet title={`${t("nameShort")}: ${t("plans:start.pageTitle")}`} />
        <PlanOptions onPlanSelection={this.handlePlanSelection} />
      </Section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPreferredPlan: (plan, amount) => dispatch(setPreferredPlan(plan, amount)),
});

export default compose(
  withTranslation(["common", "orders"]),
  connect(null, mapDispatchToProps)
)(OptionPage);
