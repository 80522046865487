import axios from "axios";
import react, { useEffect, useRef, useState } from "react";
import Playlist from "./Playlist";
import styles from "../maintenance.module.css";
import { Lists } from "./Playlist";
import { Skeleton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/common/loading-spinner";
const PlaylistSection = ({ playlistId, title, desc, isSingle }) => {
  const [videos, setVideos] = useState([]);
  const [selected, setSelected] = useState(0);
  const isless992px = useMediaQuery("(max-width:992px)");
  const [loading, setLoading] = useState(false);
  const [isExpanded, setExpanded] = useState();
  const [isSeeless, setSeeless] = useState(false);
  const textRef = useRef();
  const [scrolled, setScrolled] = useState("");
  const navigate = useNavigate();
  const fetchVideos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems`,
        {
          params: {
            part: "snippet",
            // maxResults: 10,
            playlistId: playlistId,
            key: process.env.REACT_APP_YT_KEY,
          },
        }
      );
      const filtered = response.data.items.filter(
        (item) => item.snippet.title !== "Private video"
      );
      setVideos(filtered);
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchVideos();
  }, []);
  useEffect(() => {
    const container = textRef.current;
    setExpanded(container.scrollHeight > 78);
  }, [videos, isless992px]);

  const selectVideo = (index) => {
    setSelected(index);
    const player = document.getElementById(
      videos[selected]?.snippet.resourceId.videoId + playlistId.slice(4)
    );

    // .parentElement;
    setTimeout(() => {
      player?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      if (isless992px)
        setScrolled(
          videos[selected]?.snippet.resourceId.videoId + playlistId.slice(4)
        );
    }, 700);
  };
  const handleSeemore = () => {
    navigate(`/maintenance/${playlistId}`, {
      state: { video: videos[selected] },
    });
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className={styles.PlaylistSection}>
      <Playlist
        isless992px={isless992px}
        selectVideo={selectVideo}
        videos={videos}
        selected={selected}
        title={title}
        desc={desc}
        id={playlistId.slice(4)}
        scrolled={scrolled}
        setScrolled={setScrolled}
      />
      <div>
        <h3 className={styles.title}>{videos[selected]?.snippet.title}</h3>
        <div
          className={`${styles.description} ${
            isExpanded === false ? styles.fulldesc : ""
          }`}
        >
          <div ref={textRef}>{videos[selected]?.snippet.description}</div>

          {isExpanded && (
            <a
              onClick={() => {
                setExpanded(false);
                setSeeless(true);
              }}
              className={isSingle ? styles.singleseemore : ""}
            >
              ...Read More
            </a>
          )}
          {isSeeless && (
            <a
              className={styles.seeless}
              onClick={() => {
                setSeeless(false);
                setExpanded(true);
              }}
            >
              See less
            </a>
          )}
        </div>
      </div>
      {isless992px && !isSingle && videos.length > 1 && (
        <>
          <Lists
            videos={videos?.slice(0, 4)}
            selectVideo={selectVideo}
            selected={selected}
          />
          {videos.length > 4 && (
            <button onClick={handleSeemore} className={styles.seemoreBtn}>
              See more videos
            </button>
          )}
        </>
      )}
      {isSingle ? (
        isless992px ? (
          <div className={styles.singlepage_videoList}>
            <Lists
              videos={videos}
              selectVideo={selectVideo}
              selected={selected}
            />
          </div>
        ) : (
          ""
        )
      ) : (
        <hr className={styles.line} />
      )}
    </div>
  );
};

export default PlaylistSection;
