import React, { PureComponent } from "react";
import { compose } from "redux";
import Grid from "@mui/material/Grid";
import { Field, reduxForm } from "redux-form";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";

import cart from "services/shopping-cart";
import { isRequired } from "helpers/validators";
import { renderTextField } from "helpers/form";
import Button from "components/common/button";
import Section from "components/common/section";
import * as Styled from "./styled";
import theme from "helpers/theme";

export class StartingForm extends PureComponent {
  render() {
    const { t, handleSubmit, submitting } = this.props;

    return (
      <Section>
        <Styled.Content>
          <Typography
            variant="h2"
            color={theme.palette.primary.main}
            align="center"
          >
            {t("home:getStartedTitle")}
          </Typography>
          <Typography
            variant="h1"
            color={theme.palette.secondary.main}
            align="center"
            style={{ marginLeft: "-0.02em" }}
          >
            {t("home:getStartedCTA")}
          </Typography>

          <Styled.Form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  name="street"
                  label={t("street")}
                  component={renderTextField}
                  validate={isRequired}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Field
                  name="city"
                  label={t("city")}
                  component={renderTextField}
                  validate={isRequired}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Field
                  name="state"
                  label={t("state")}
                  component={renderTextField}
                  validate={isRequired}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Field
                  name="postal"
                  label={t("postal")}
                  component={renderTextField}
                  validate={isRequired}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center", marginTop: 50 }}>
                <Button type="submit" disabled={submitting} fullWidth={false}>
                  {t("getProtected")}
                </Button>
              </Grid>
            </Grid>
          </Styled.Form>
        </Styled.Content>
      </Section>
    );
  }
}

const formOptions = {
  form: "gettingStarted",
  destroyOnUnmount: false,
  initialValues: cart.address || { state: "UT" },
};

export default compose(
  withTranslation(["common", "home"]),
  reduxForm(formOptions)
)(StartingForm);
