import { useParams } from "react-router-dom";
import PlaylistSection from "../components/Playlist-Section";
import styles from "./singlePlaylist.module.css";
const SinglePlaylist = () => {
  const { playlistId } = useParams();
  return (
    <div className={styles.container}>
      <PlaylistSection playlistId={playlistId} isSingle />;
    </div>
  );
};

export default SinglePlaylist;
