import React from "react";
import { Route, Routes } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Helmet from "react-helmet";
import { getFormValues } from "redux-form";
import { connect } from "react-redux";

import { Grid, Stepper, Step, StepButton, Typography } from "@mui/material";
// import withWidth, { isWidthDown } from '@mui/material/withWidth';
import { getConsumerOrderSummary } from "store/reducers";

import Section from "components/common/section";
import PropertyInfo from "./property-info";
import PlanBuilder from "./plan-builder";
import Checkout from "./checkout";

const steps = ["propertyInfo", "planSelection", "checkout"];

class Orders extends React.Component {
  state = { completed: {}, activeStep: 0 };

  componentDidMount() {
    localStorage.setItem("customer_details", []);
    console.log("CD:", localStorage.getItem("customer_details").length);
    this.determineStep();
  }

  componentDidUpdate({ location }) {
    if (location.pathname !== this.props.location.pathname) {
      this.determineStep();
    }
  }

  determineStep = () => {
    const { location, address, match } = this.props;
    const paths = [
      `/homeowner/order`,
      `/homeowner/order/build`,
      `/homeowner/order/checkout`,
    ];

    if (location.stepper || location.redirect) {
      location.pathname = "/homeowner/order/checkout";
    } else {
      // address hasn't been provided yet
      // if (!address && location.pathname !== match?.path) {
      // if (!address && location.pathname !== match?.pathname) {
      if (!address || this.props.formErr) {
        this.props.navigate(`/homeowner/order`);
        location.pathname = `/homeowner/order`;
      }
    }
    const activeStep = paths.indexOf(location.pathname);
    this.setState({ activeStep });
  };

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
    if (step === 0) {
      this.props.navigate(`/homeowner/order`);
    }
    if (step === 1) {
      this.props.navigate(`/homeowner/order/build`);
    }
    if (step === 2) {
      if (Boolean(Object.keys(this.props.total).length)) {
        this.props.navigate(`/homeowner/order/checkout`);
      } else {
        this.props.navigate(`/homeowner/order/build`);
      }
    }
  };
  renderSteps = () =>
    steps.map((step, index) => (
      <Step key={`step-${index}`}>
        {/* <StepLabel>{this.props.t(step)}</StepLabel> */}
        <StepButton
          onClick={this.handleStep(index)}
          completed={this.state.completed[index]}
          sx={{
            "& span": {
              fontSize: "18px",
              lineHeight: "1.46429em",
              fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            },
            margin: { xs: "-10px", md: "auto" },
          }}
        >
          {this.props.t(step)}
        </StepButton>
      </Step>
    ));

  render() {
    const { t, match, classes, location } = this.props;
    const { activeStep } = this.state;
    // console.log(
    //   this.props.total,
    //   "total900",
    //   Boolean(Object.keys(this.props.total).length)
    // );
    return (
      <React.Fragment>
        <Helmet title={`${t("common:nameShort")}: ${t("title")}`} />

        <Grid container direction="column">
          <Section style={{ padding: `16px 0` }}>
            {!this.props.location.stepper && (
              <Typography
                variant="h3"
                color="primary"
                align="center"
                gutterBottom
              >
                {t("newWarranty")}
              </Typography>
            )}
            {this.props.location.stepper && (
              <Typography
                variant="h3"
                color="primary"
                align="center"
                gutterBottom
              >
                {t("checkout")}
              </Typography>
            )}
            {!this.props.location.stepper && (
              <Stepper
                nonLinear
                className={classes?.stepper}
                activeStep={activeStep}
                sx={{
                  // width: "80%",
                  margin: { xs: "-10px ", md: "0 auto" },
                  backgroundColor: "transparent",
                  width: { xs: "100%", sm: "80%" },
                  padding: "24px",
                  lineHeight: "1.46429em",
                  fontSize: "18px",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "start", md: "center" },
                }}
                // orientation={isWidthDown('sm', width) ? 'vertical' : 'horizontal'}
              >
                {this.renderSteps()}
              </Stepper>
            )}
          </Section>
          <Routes>
            <Route
              exact
              path={`/`}
              element={<PropertyInfo navigate={this.props.navigate} />}
            />
            <Route
              exact
              path={`build`}
              element={
                <PlanBuilder
                  navigate={this.props.navigate}
                  location={this.props.location}
                />
              }
            />
            <Route
              exact
              path={`checkout`}
              element={
                <Checkout
                  match={this.props.match}
                  location={this.props.location}
                  navigate={this.props.navigate}
                />
              }
            />
          </Routes>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  stepper: {
    width: "80%",
    margin: "0 auto",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stepIcon: {
    color: theme.palette.secondary.main,
  },
});

const mapStateToProps = (state) => ({
  address: getFormValues("homeowner-property-info")(state),
  formErr: state.form["homeowner-property-info"]?.syncErrors,
  total: getConsumerOrderSummary(state).total,
});

export default compose(
  // withStyles(styles),
  // withWidth(),
  withTranslation("homeowner"),
  connect(mapStateToProps)
)(Orders);
