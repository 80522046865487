import styled from '@emotion/styled'
import MuiCard from '@mui/material/Card';

import BackgroundImage from 'assets/images/front-door.jpg';

export const Container = styled('div')(({ theme }) => ({
  label: 'auth-container',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Card = styled(MuiCard)(({ theme }) => ({
  maxWidth: '50%',
  backgroundColor: `rgba(255, 255, 255, 0.85)`,

  '& .card-header': {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[0],
  },
}));
