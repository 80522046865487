class ShoppingCart {
  constructor() {
    this.id = `sb-cart`;
    this.keys = {
      address: `${this.id}-address`,
      plan: `${this.id}-plan`
    };
  }

  clear() {
    window.sessionStorage.clear();
  }

  _setJSON(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  _getJSON(key, defaultJSON = {}) {
    try {
      const text = window.sessionStorage.getItem(key);
      const json = JSON.parse(text);
      return json ? json : defaultJSON;
    } catch (e) {
      console.error(e);
      return defaultJSON;
    }
  }

  set address(address = { street: '', unit: '', city: '', state: 'UT', zip: '' }) {
    this._setJSON(this.keys.address, address);
  }

  get address() {
    return this._getJSON(this.keys.address);
  }

  get total() {
    return 0;
  }

  set plan(plan = { name: '', amount: 0 }) {
    this._setJSON(this.keys.plan, plan);
  }

  get plan() {
    return this._getJSON(this.keys.plan);
  }
}

export default new ShoppingCart();
