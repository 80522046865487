export default {
  nameFull: 'SilverBack Home Warranty',
  nameShort: 'SilverBack',
  getStarted: 'Empezar',
  downloadPlans: 'Descargar Planes',
  getProtected: 'Obtener Protección',
  goHome: 'Volver a casa',
  scf_checkout: 'Servicio de pago Tarifa de llamada',
  contactUs: 'Contáctenos',
  contactMessage: '...o envianos un mensaje',
  send: 'Enviar',
  submit: 'Enviar',
  continue: 'Continuar',
  yes: 'Sí',
  no: 'No',

  navigation: {
    newPolicy: 'Hacer un Pedido',
    requestService: 'Solicitar Servicio',
    contact: 'Contáctenos',
    agentServices: 'Servicios de Agente',
    homeowner: 'Consumidor'
  },

  street: 'Dirección',
  unit: 'Unidad Numérica',
  city: 'Ciudad',
  state: 'Estado',
  postal: 'Código Postal',

  contactInformation: 'Información del contacto',
  homeowner: 'Dueño de Casa',
  listingAgent: 'Agente de Listado',
  buyerAgent: `Agente del Comprador`,
  escrowOfficer: 'Funcionario de Custodia',
  transactionCoordinator: 'Coordinador de Transacciones',

  buyerInformation: 'Información de contacto del comprador',
  sellerInformation: 'Información de contacto del vendedor',
  titleEscrowInformation: 'Información del título / depósito de garantía',

  name: 'Nombre',
  phone: 'Teléfono',
  email: 'Email',
  phoneNumberConsentHelpText:
    "Pueden aplicarse tarifas por mensajes y datos. Los operadores no son responsables de mensajes retrasados o no entregados. Envía 'ayuda' para obtener asistencia y 'detener' para darte de baja.",
  phoneNumberConsentMsgText:
    'Al marcar esta casilla, aceptas recibir mensajes de texto de SilverBackHW.',
  privacyPolicy: 'Política de Privacidad',
  termsAndConditions: 'Términos y Condiciones',
  company: 'Nombre de empresa',
  agentName: 'Nombre del agente',
  agentEmail: 'Correo electrónico del agente',
  agentPhone: 'Teléfono del agente',
  escrowCompany: 'Fideicomiso',
  message: `Que tienes que decir?`,

  totalDue: 'Total Debido',
  plan: 'Plan',
  options: 'Opciones',
  total: 'Total',
  policy: 'Policy',

  developedBy: 'Creado por',

  pageNotFound: 'Página No Encontrada: 404',
  pageNotFoundMessage:
    '¡Lo Siento! La Página Que Estás Buscando No Se Puede Encontrar',
  validationErrors: {
    NaN: 'Please enter a digit character',
    notInRange: 'quantity is not in available range',
    notInteger: 'quantity should be an integer digit'
  },
  applyCoupon: 'Código promocional',
  invalid_coupon: 'Código de promoción no válido',
  quote: 'por favor llame para una cotización : 833-750-6400'
};
