import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { reduxForm, Field } from "redux-form";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import styled from "@emotion/styled";

import { renderCheckbox } from "helpers/form";

class CustomPlan extends React.Component {
  renderListItems = () =>
    this.props.features.map((item, index) => (
      <ListItem key={`item-${item.label}`} dense divider>
        <ListItemText
          primaryTypographyProps={{ align: "center" }}
          style={{ height: 18 }}
        >
          <Field
            name={item.label}
            component={renderCheckbox}
            style={{ height: 18, width: 18 }}
            disabled={!this.props.selected}
          />
        </ListItemText>
        {/* <ListItemText>{item?.price}</ListItemText> */}
      </ListItem>
    ));

  render() {
    const { features, selected, onSelect } = this.props;
    // console.log(selected, onSelect, "selected650");
    return (
      <StyledCard>
        <CardHeader
          title="Custom Plan *"
          titleTypographyProps={{
            color: "secondary",
          }}
          action={
            <Checkbox
              checked={selected}
              style={{ paddingTop: 8 }}
              onChange={onSelect}
            />
          }
          style={{ maxHeight: 68 }}
        />
        <CardContent>
          <form>
            <List>{this.renderListItems(features)}</List>
          </form>
        </CardContent>
      </StyledCard>
    );
  }
}

CustomPlan.defaultProps = {
  plan: {},
};

const StyledCard = styled(Card)(() => ({
  height: "100%",
}));

export default compose(
  withTranslation("homeowner"),
  reduxForm({ form: "homeowner-custom-plan", destroyOnUnmount: false })
)(CustomPlan);
