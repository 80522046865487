export default {
  agents: {
    pageTitle: 'Agentes',
    header: `¿Necesita un mayor apoyo de su Ecosistema inmobiliario?`,
    subHeader: `¡Estamos aquí para hacer exactamente eso!`,
    labels: [
      'Folleto de Open House',
      'coaching de agentes',
      'Encuesta de Soporte de Agente',
    ],
  },
};
