import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "components/common/button";

import * as Styled from "./styled";

class PropertyPopup extends Component {
  handleChange = (event) => {
    this.props.onpropertychange(event.target.value);
  };

  rendermenu = () => {
    if (this.props.claims_list.length > 1) {
      var MakeItem = function (claim) {
        return (
          <MenuItem
            sx={{
              fontWeight: "700",
              fontFamily: `'PT Sans Narrow', 'Roboto', 'sans-serif'`,
              lineHeight: "1.5em",
            }}
            value={claim}
          >
            {claim.Address_1}
          </MenuItem>
        );
      };
      return (
        <Select
          variant="standard"
          onChange={this.handleChange}
          style={{ width: 300 }}
        >
          {this.props.claims_list.map(MakeItem)}
        </Select>
      );
    } else {
      return <></>;
    }
  };

  render() {
    const { claims, open, t, onClose } = this.props;
    return (
      <Styled.Modal open={open} onClose={onClose} claims={claims}>
        <Styled.ModalContent>
          <Typography variant="subtitle1" color="primary">
            {t("claims:property_popup.title")}
          </Typography>
          <FormControl style={{ marginTop: "20px" }} variant="outlined">
            {this.rendermenu()}
          </FormControl>
          <Button onClick={onClose} color="secondary" style={{ marginTop: 20 }}>
            {t("claims:property_popup.close")}
          </Button>
        </Styled.ModalContent>
      </Styled.Modal>
    );
  }
}

export default withTranslation(["common", "claims"])(PropertyPopup);
